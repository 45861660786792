import college1 from "../assets/admin/college1.jpeg"; // Adjust the actual file extension and path
import college2 from "../assets/admin/college2.jpeg";
import college3 from "../assets/admin/college3.jpeg";
import college4 from "../assets/admin/college4.jpeg";
import college5 from "../assets/admin/college5.jpeg";
import college6 from "../assets/admin/college6.jpeg";
import college7 from "../assets/admin/college7.jpeg";
import college8 from "../assets/admin/college8.jpeg";

export const topCollegesData = [
  { img: college1, name: "Delhi University" },
  { img: college2, name: "IIM Ahmedabad" },
  { img: college3, name: "IIM Calcutta" },
  { img: college4, name: "XLRI" },
  { img: college5, name: "IIT Madras" },
  { img: college6, name: "Bits Pilani" },
  { img: college7, name: "VIT Bangalore" }, // Corrected spelling
  { img: college8, name: "Manipal Academy of Higher Education" },
];
