import React from "react";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import Economic from "../Components/Economic/Economic";


export const Economicpage = () => {
  return (
    <div className="overflow-hidden">
      <Header />
      <Economic/>
      <Footer />
    </div>
  );
};
