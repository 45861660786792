import React from "react";
import "./session.css";

const Sessions = () => {
  return (
    <div className="justify-center">
      <div className="items-center">
        <div className="max-w-[1240px] mx-auto grid1 grid1-cols-1 mt-[80px] px-10">
          <div className="flex flex-col lg:flex-row items-center shadow-xl my-4 border border-2px bg-gray-200 rounded-3xl  mb-[80px] mr-6 ml-6">
            <div className="lg:w-[600px] lg:h-[600px] w-full h-[400px] relative rounded-3xl">
              <img
                className="w-full h-full object-cover lg:rounded-l-3xl"
                src={require("../../assets/success/session3.png")}
                alt=""
              />
            </div>
            <div className="lg:w-[600px] lg:h-[600px] w-full py-10 px-8">
              <h1 className="lg:text-5xl text-2xl  text-center font-bold mb-6 mt-8">
                Book An Online Counseling Session
              </h1>
              <div>
                <p className="text-justify text-md mt-10">
                  Guide4Careers brings to you a one-stop-solution to get answers
                  to all your career-related questions in a 30 Minute 1-on-1
                  session with industry-leading Expert Career Counsellors!
                </p>
              </div>
              <button className="w-[150px] h-[50px] border border-2xl rounded-xl bg-black text-white mt-8 mx-auto hover:scale-110 duration-100 hover:text-gray-300 flex items-center justify-center">
                BOOK NOW
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sessions;
