import React from "react";
import { FaEnvelope, FaPhone } from "react-icons/fa";

const Contactus = () => {
  return (
    <div className="max-w-[1000px] mx-auto  mt-[80px]  relative py-6">
      <div className=" relative lg:left-[-10%] flex md:flex-row flex-col items-start shadow-xl my-4 border border-2px bg-white rounded-3xl mb-[80px] ">
        <div className=" md:w-[500px] w-[400px] lg:hidden py-10 px-8 bg-gradient-to-r from-gray-300 to-gray-800 text-white rounded-r-3xl relative z-10">
          <h2 className="text-3xl font-bold mb-6 text-center">
            Drop a Message
          </h2>
          <p className="text-md text-center">
            Have any inquiry or some feedback for us?
          </p>
          <p className="text-md text-center">
            Fill out the form below to contact our team.
          </p>
          <div className="max-w-screen-lg mx-auto">
            <form>
              <div className="mb-4">
                <label htmlFor="name" className="text-lg font-bold">
                  Name:
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="w-full sm:w-[300px] md:w-[400px] lg:w-[500px] border p-2 rounded-3xl mt-1 text-black outline-none"
                  placeholder="Your Name"
                />
              </div>

              <div className="mb-4">
                <label htmlFor="email" className="text-lg font-bold">
                  Email:
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="w-full sm:w-[300px] md:w-[400px] lg:w-[500px] border p-2 rounded-3xl mt-1 text-black outline-none"
                  placeholder="Your Email"
                />
              </div>

              <div className="mb-4">
                <label htmlFor="phone" className="text-lg font-bold">
                  Phone:
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  className="w-full sm:w-[300px] md:w-[400px] lg:w-[500px] border p-2 rounded-3xl mt-1 text-black outline-none"
                  placeholder="Your Phone"
                />
              </div>

              <div className="mb-4">
                <label htmlFor="subject" className="text-lg font-bold">
                  Subject:
                </label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  className="w-full sm:w-[300px] md:w-[400px] lg:w-[500px] border p-2 rounded-3xl mt-1 text-black outline-none"
                  placeholder="Subject"
                />
              </div>

              <div className="mb-4">
                <label htmlFor="message" className="text-lg font-bold">
                  Message:
                </label>
                <textarea
                  id="message"
                  name="message"
                  className="w-full sm:w-[300px] md:w-[400px] lg:w-[500px] border p-2 rounded-3xl mt-1 text-black outline-none"
                  placeholder="Your Message"
                ></textarea>
              </div>

              <div className="mb-4">
                <button
                  type="submit"
                  className="w-full sm:w-[300px] md:w-[400px] lg:w-[500px] h-[50px] border border-2xl rounded-3xl bg-gray-800 text-white mt-4 mx-auto"
                >
                  Send Message
                </button>
              </div>
            </form>

            <div className="w-full sm:w-[300px] md:w-[400px] lg:w-[530px] h-[400px] relative mt-10 mx-auto">
              <iframe
                title="Map"
                width="100%"
                height="100%"
                frameBorder="0"
                style={{ border: 0 }}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.823547450799!2d77.2227851!3d28.6293385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce49bfdbbbe8f%3A0xa234f4d5e8f3a691!2sHead%20Office%20-%20L-3%2C%20Kanchanjunga%20Building%20Barakhamba%20Road%2C%20Connaught%20Place!5e0!3m2!1sen!2sin!4v1640850714245!5m2!1sen!2sin"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
        <div className="max-w-screen-lg mx-auto">
          <div className="w-full md:w-[600px] lg:w-[600px] h-[600px] relative mx-auto">
            <h3 className="text-center text-2xl py-8 px-6">
              Are you facing any problem?
            </h3>
            <img
              className="md:w-full md:h-full w-40 h-40 mx-auto object-cover overflow-hidden rounded-tl-3xl rounded-bl-3xl py-4 px-6"
              src={require("../../assets/icon/Pngtree.png")}
              alt=""
            />
            <p className="text-center text-xl py-4 px-6">
              If you need instant support, use the live chat option to reach us
              quickly.
            </p>
            <p className="text-center text-xl px-6">
              Our support will reply as soon as possible after you send us a
              message.
            </p>
            <div className="w-full md:w-[600px] mx-auto text-center lg:w-[600px]  flex flex-col justify-center mt-8">
              <p className="text-xl py-4 px-6 mx-auto">
                Or you can contact at:
              </p>
              <p className="text-center text-xl py-4 px-6">
                <FaEnvelope className="inline-block mr-2" />
                Email: info@templeoflearning.com
              </p>
              <p className="text-center text-xl px-6 py-2">
                <FaPhone className="inline-block mr-2" />
                Phone: +91-98100 33495
              </p>
            </div>
          </div>
        </div>

        <div className="w-[600px] max-lg:hidden py-10 px-8 bg-gradient-to-r from-gray-300 to-gray-800 text-white rounded-r-3xl relative z-10">
          <h2 className="text-3xl font-bold mb-6 text-center">
            Drop a Message
          </h2>
          <p className="text-md text-center">
            Have any inquiry or some feedback for us?
          </p>
          <p className="text-md text-center">
            Fill out the form below to contact our team.
          </p>
          <div className="max-w-screen-lg mx-auto">
            <form>
              <div className="mb-4">
                <label htmlFor="name" className="text-lg font-bold">
                  Name:
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="w-full sm:w-[300px] md:w-[400px] lg:w-[500px] border p-2 rounded-3xl mt-1 text-black outline-none"
                  placeholder="Your Name"
                />
              </div>

              <div className="mb-4">
                <label htmlFor="email" className="text-lg font-bold">
                  Email:
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="w-full sm:w-[300px] md:w-[400px] lg:w-[500px] border p-2 rounded-3xl mt-1 text-black outline-none"
                  placeholder="Your Email"
                />
              </div>

              <div className="mb-4">
                <label htmlFor="phone" className="text-lg font-bold">
                  Phone:
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  className="w-full sm:w-[300px] md:w-[400px] lg:w-[500px] border p-2 rounded-3xl mt-1 text-black outline-none"
                  placeholder="Your Phone"
                />
              </div>

              <div className="mb-4">
                <label htmlFor="subject" className="text-lg font-bold">
                  Subject:
                </label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  className="w-full sm:w-[300px] md:w-[400px] lg:w-[500px] border p-2 rounded-3xl mt-1 text-black outline-none"
                  placeholder="Subject"
                />
              </div>

              <div className="mb-4">
                <label htmlFor="message" className="text-lg font-bold">
                  Message:
                </label>
                <textarea
                  id="message"
                  name="message"
                  className="w-full sm:w-[300px] md:w-[400px] lg:w-[500px] border p-2 rounded-3xl mt-1 text-black outline-none"
                  placeholder="Your Message"
                ></textarea>
              </div>

              <div className="mb-4">
                <button
                  type="submit"
                  className="w-full sm:w-[300px] md:w-[400px] lg:w-[500px] h-[50px] border border-2xl rounded-3xl bg-gray-800 text-white mt-4 mx-auto"
                >
                  Send Message
                </button>
              </div>
            </form>

            <div className="w-full sm:w-[300px] md:w-[400px] lg:w-[530px] h-[400px] relative mt-10 mx-auto">
              <iframe
                title="Map"
                width="100%"
                height="100%"
                frameBorder="0"
                style={{ border: 0 }}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.823547450799!2d77.2227851!3d28.6293385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce49bfdbbbe8f%3A0xa234f4d5e8f3a691!2sHead%20Office%20-%20L-3%2C%20Kanchanjunga%20Building%20Barakhamba%20Road%2C%20Connaught%20Place!5e0!3m2!1sen!2sin!4v1640850714245!5m2!1sen!2sin"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contactus;
