import React from "react";

const Defense = () => {
  return (
    <div className=" mt-[100px]">
      <div className="w-full grid-cols-1 flex items-center justify-center">
        <div className="w-full h-[300px] bg-[#64964d]">
          <h2 className="text-white text-3xl text-center mt-[120px]">
            Career Library
          </h2>
        </div>
      </div>
      <div className="mx-[100px] text-justify">
        <h3 className="text-2xl mt-10 ">Defense</h3>
        <hr ></hr>
        <p className=" mt-6 text-md">
          A career in defense forces promises one of the most prestigious and
          respected positions in the country. The Indian Armed Forces are the
          military forces of India, which consists of four professional
          uniformed services: The Indian Army, Indian Air Force, Indian Navy and
          Indian Coast Guard. The various paramilitary organizations and various
          inter-service institutions also help the Indian Armed Forces. The
          Ministry of Defense is responsible for the management of armed forces
          in India.
          <br></br>
          <p className="mt-4">
            Armed forces offer very exciting career to young men and women in
            several fields. Career in the forces promises a life full of
            adventure. To ensure that appropriate candidates are chosen, a
            comprehensive selection process is adopted by the armed forces
            through Service Selection Board (SSB) Interview.
          </p>
          <br></br>
          <p className="mt-4">
            Selection procedure involves three phases: Objective test, Interview
            and medical examination. Candidates applying for Air force and Navy
            must have Physics and Maths in the +2 level exam. Students can apply
            after class 12 (NDA) or after graduation (CDS) for defense services.
          </p>
        </p>
      </div>
      <p className="mt-6 text-md mx-[100px]">
        Candidates finally selected by SSB are absorbed by armed forces.
        Individuals are exposed to various forms of training and promotions at
        different levels.
      </p>
      <div className="flex flex-wrap mt-8 mx-[100px] mb-8">
        <div className="items-left w-[400px] h-[200px] mb-[40px] ">
          <img
            src={require("../../assets/careerlibrary/34.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">Indian Army</p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px] ml-10 ">
          <img
            src={require("../../assets/careerlibrary/35.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">Indian Navy</p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px] ml-10 ">
          <img
            src={require("../../assets/careerlibrary/36.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">
            Indian Air Force
          </p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px]  mt-10">
          <img
            src={require("../../assets/careerlibrary/37.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">
            Central Armed Police Forces
          </p>
        </div>
      </div>
    </div>
  );
};

export default Defense;
