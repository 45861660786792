import React from "react";

const Engineering = () => {
  return (
    <div className=" mt-[100px]">
      <div className="w-full grid-cols-1 flex items-center justify-center">
        <div className="w-full h-[300px] bg-[#64964d]">
          <h2 className="text-white text-3xl text-center mt-[120px]">
            Career Library
          </h2>
        </div>
      </div>
      <div className="mx-[100px] text-justify">
        <h3 className="text-2xl mt-10 ">Engineering</h3>
        <hr ></hr>
        <p className=" mt-6 text-md">
          Engineering is one of the most versatile degrees spanning multiple
          domains ranging from aerospace to automobiles to healthcare to
          business & many more. Engineers are at the center of innovation and
          one of the most sought after professionals in the country. They
          provide technological solutions to the problems, issues and ideas that
          affect every area of our lives and are responsible for technological
          advancements. Depending upon one's interest, one can specialize in
          multiple fields of engineering such as Electronics, Computer Science,
          Mechanical, Civil & Aerospace amongst others.
          <br></br>
          <p className="mt-4">
            With increasing competition in the field, huge numbers of
            engineering colleges have mushroomed across the country. However,
            competition for the premier colleges remains extremely high.
          </p>
          <br></br>
          <p className="mt-4">
            A four year Bachelor's degree in Engineering can be quite rewarding
            if completed from the marque colleges of the country. In order to
            build a career in Engineering and Technology, one should have a
            combination of PCM (Physics, Chemistry and Mathematics) at the 10+2
            level.
          </p>
          <br></br>
          <p className="mt-4">
            An engineering degree from the coveted colleges opens doors for some
            of the top companies in the world like Google, Facebook, Amazon,
            Microsoft amongst others recruiters.
          </p>
        </p>
      </div>
      <p className="mt-6 text-md mx-[100px]">
        Trending fields: Computer Science Engineering, Electronic &
        Communication Engineering, Sound Engineering, Marine Engineering
      </p>
      <div className="flex flex-wrap mt-8 mx-[100px] mb-8">
        <div className="items-left w-[400px] h-[200px] mb-[40px] ">
          <img
            src={require("../../assets/careerlibrary/49.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">
            Electrical and Electronics Engineering
          </p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px] ml-10 ">
          <img
            src={require("../../assets/careerlibrary/50.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">
            Mechanical and Automobile Engineering
          </p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px] ml-10 ">
          <img
            src={require("../../assets/careerlibrary/51.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">
            Civil Engineering
          </p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px] mt-10 ">
          <img
            src={require("../../assets/careerlibrary/52.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">
            Chemical and Petroleum Engineering
          </p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px] ml-10 mt-10 ">
          <img
            src={require("../../assets/careerlibrary/53.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">
            Environmental Engineering
          </p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px] ml-10 mt-10 ">
          <img
            src={require("../../assets/careerlibrary/54.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">
            Aerospace Engineering
          </p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px] mt-10 ">
          <img
            src={require("../../assets/careerlibrary/55.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">
            CS, IT and Software Engineering
          </p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px] ml-10 mt-10">
          <img
            src={require("../../assets/careerlibrary/56.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">
            Biomedical and Biotechnology Engineering
          </p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px] ml-10 mt-10">
          <img
            src={require("../../assets/careerlibrary/57.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">
            Power Engineering
          </p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px] mt-10">
          <img
            src={require("../../assets/careerlibrary/60.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">Robotics</p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px] ml-10 mt-10">
          <img
            src={require("../../assets/careerlibrary/61.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">
            Genetic Engineering
          </p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px] ml-10 mt-10">
          <img
            src={require("../../assets/careerlibrary/62.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">
            Artificial Intelligence and Machine Learning
          </p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px] mt-10 ">
          <img
            src={require("../../assets/careerlibrary/63.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">
            Nanotechnology
          </p>
        </div>
      </div>
    </div>
  );
};

export default Engineering;
