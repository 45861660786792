import React from "react";
import mailIcon from "../../assets/icon/mail.svg";
import "./banner.css";

const Banner = () => {
  return (
    <div className="banner text-white xl:h-[34em] lg:h-[34em] md:h-[34em] 2xl:h-[38em] h-[34em] flex items-center ">
      <div className="max-w-[1100px] mx-auto flex flex-col justify-center items-center mt-[10%] ">
        <div className="text-center md:text-left">
          <p className="page_font text-black font-bold p-2 md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-5xl text-xl text-center">
            Know Your Ideal Career
          </p>
        </div>
         <div className="flex items-center  justify-center flex-col md:flex-row w-full mt-4 ">
          <div className="mb-4 md:mb-0 w-full  mx-auto  md:w-auto">
            <div className="bg-white flex border border-2px rounded-md shadow-e1 items-center p-4 h-[50px] w-[350px] mx-auto md:w-[350px] ">
              <div className="flex-1 max-md:flex-row flex items-center mx-auto mb-2 md:mb-0">
                <div className="">
                  <span>
                    <img src={mailIcon} alt="mainIcon" />
                  </span>
                </div>
                <div className="">
                  <input
                    type="text"
                    placeholder="Enter your mail"
                    className="border-none focus:ring-0 w-full ml-3 text-black"
                  />
                </div>
              </div>
              <div className="mt-2 md:mt-0  ml-4 ">
                <button className=" w-full md:w-auto text-black font-bold">
                  Get Started Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
