import React from "react";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import Computerapplication from "../Components/Computerapplication/Computerapplication";


export const ComputerApplication = () => {
  return (
    <div className="overflow-hidden">
      <Header />
     <Computerapplication/>
      <Footer />
    </div>
  );
};
