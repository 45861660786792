import React from "react";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import Aviation from "../Components/Aviation/Aviation";


export const Aviationpage = () => {
  return (
    <div className="overflow-hidden">
      <Header />
      <Aviation/>
      <Footer />
    </div>
  );
};
