import React from "react";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import Animationandgraphic from "../Components/Animationandgraphic/Animationandgraphic";


export const AnimationAndGraphic = () => {
  return (
    <div className="overflow-hidden">
      <Header />
      <Animationandgraphic />
      <Footer />
    </div>
  );
};
