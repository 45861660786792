import React from "react";

const Forstudents = () => {
  return (
    <div className="flex items-center">
      <div className="mx-auto ">
        <div className="lg:text-3xl text-md font-bold text-black text-center mt-10 ">
          <h2>How We Help</h2>
        </div>
        <div className="max-w-[1240px] mx-auto flex flex-wrap   gap-8 mt-10 mr-4 ml-4">
          <div className="flex-none shadow-xl h-[200px] w-[270px] mx-auto my-4 border border-2px rounded-2xl hover:scale-110 duration-100 hover:bg-[#f3f0f0] flex flex-col items-center">
            <img
              src={require("../../assets/career/book.png")}
              alt=""
              className="w-24 h-20 object-cover rounded-full px-2 py-2"
            />
            <p className="lg:text-md text-sm text-black px-4 py-3">
              Humanities, Science or Commerce - pick the right stream for you
              based on your interests & aptitude
            </p>
          </div>
          <div className="flex-none shadow-xl h-[200px] w-[270px] mx-auto my-4 border border-2px rounded-2xl hover:scale-110 duration-100 hover:bg-[#f3f0f0] flex flex-col items-center">
            <img
              src={require("../../assets/career/book2.png")}
              alt=""
              className="w-24 h-20 object-cover rounded-full px-2 py-2"
            />
            <p className="lg:text-md text-sm text-black px-4 py-3">
              Learn in detail about all the career options available for your
              chosen stream and subject combinations
            </p>
          </div>
          <div className="flex-none shadow-xl h-[200px] w-[270px] mx-auto my-4 border border-2px rounded-2xl hover:scale-110 duration-100 hover:bg-[#f3f0f0] flex flex-col items-center">
            <img
              src={require("../../assets/career/letter.png")}
              alt=""
              className="w-24 h-20 object-cover rounded-full px-2 py-2"
            />
            <p className="lg:text-md text-sm text-black px-4 py-3">
              Build a holistic profile aligned to your career interests &
              ambitions with guidance from expert coaches
            </p>
          </div>
          <div className="flex-none shadow-xl h-[200px] w-[270px] mx-auto my-4 border border-2px rounded-2xl hover:scale-110 duration-100 hover:bg-[#f3f0f0] flex flex-col items-center">
            <img
              src={require("../../assets/career/officebad.webp")}
              alt=""
              className="w-24 h-20 object-cover rounded-full px-2 py-2"
            />
            <p className="lg:text-md text-sm text-black px-4 py-3 ">
              Deep dive into your preferred career domains through virtual
              career simulations & internships
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forstudents;
