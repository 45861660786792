import React from "react";

const Commerceaccount = () => {
  return (
    <div className=" mt-[100px]">
      <div className="w-full grid-cols-1 flex items-center justify-center">
        <div className="w-full h-[300px] bg-[#64964d]">
          <h2 className="text-white text-3xl text-center mt-[120px]">
            Career Library
          </h2>
        </div>
      </div>
      <div className="mx-[100px] text-justify">
        <h3 className="text-2xl mt-10 ">Commerce & Accounts</h3>
        <hr ></hr>
        <p className=" mt-6 text-md">
          A career in Commerce & Accounts opens the pathway to multiple career
          options in the field of business management, accounting, banking &
          finance as well as marketing sales. One can pursue degree programs as
          well as certification programs or a combination of both at the
          graduate level. Many students are able to master both degree and
          certification programs with dedication and hard work.
          <br></br>
          <p className="mt-4">
            Bachelor in Commerce, Bachelor in Business Administration, Bachelor
            in Management Studies, Bachelor of Commerce in Banking & Insurance,
            Bachelor of Commerce in Financial Markets, Chartered Accountancy,
            Company Secretary, Cost Accountancy, Chartered Financial Analyst,
            Forensic Accounting, Economics, Actuarial Sciences are some of the
            most sought after programs in this domain. Chartered Accountancy is
            one of the most sought after and respected certification programs
            for aspirants.
          </p>
          <br></br>
          <p className="mt-4">
            Over the last decade, a degree in commerce & accounts is further
            enriched by several specialized pathways in sectors like Finance,
            Banking and Insurance. Thus the career prospects in commerce and its
            interdisciplinary domains are extremely bright. Very good growth
            prospects, shorter learning and training time-span, high employment
            opportunities are some of the factors which have made a career in
            commerce and accounts quite popular amongst the youth. Employment
            opportunities are excellent with top MNC's across industries, Audit
            Firms and CA Firms who are always on the lookout for bright talent
            in this domain.
          </p>
        </p>
      </div>
      <p className="mt-6 text-md mx-[100px]">
        Trending Fields: MBA, CFA, CA, BBA, BMS, Actuarial Sciences, Economics,
        Entrepreneurship
      </p>
      <div className="flex flex-wrap mt-8 mx-[100px] mb-8">
        <div className="items-left w-[400px] h-[200px] mb-[40px]  ">
          <img
            src={require("../../assets/careerlibrary/26.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">Commerce</p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px] ml-10">
          <img
            src={require("../../assets/careerlibrary/27.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">
            Chartered Accountant (CA)
          </p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px] ml-10 ">
          <img
            src={require("../../assets/careerlibrary/28.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">
            Chartered Financial Analyst (CFA)
          </p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px] mt-10">
          <img
            src={require("../../assets/careerlibrary/29.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">
            Cost Accountant
          </p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px] ml-10 mt-10">
          <img
            src={require("../../assets/careerlibrary/30.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">
            Company Secretary (CS)
          </p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px]  ml-10 mt-10">
          <img
            src={require("../../assets/careerlibrary/31.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">
            Forensic Accounting
          </p>
        </div>
      </div>
    </div>
  );
};

export default Commerceaccount;
