import React, { useState } from "react";
import "./viewcareer.css";

const careers = [
  {
    id: "engineering",
    title: "Engineering",
    text: "Animation and graphics is the combination of art with technology to produce movies, websites, digital content, video games etc. This domain requires having basic knowledge of fine arts along with ability to use computers and technology for creating digital special effects, producing 2D and 3D pro...Read more",
    image: require("../../assets/viewcareer/1.Engineering-Cover.png"),
    subCareers: [
      {
        title: "Electrical and Electronics Engineering",
        image: require("../../assets/careerlibrary/49.png"),
      },
      {
        title: "Mechanical and Automobile Engineering",
        image: require("../../assets/careerlibrary/50.png"),
      },
      {
        title: "Civil Engineering",
        image: require("../../assets/careerlibrary/51.png"),
      },
      {
        title: "Chemical and Petroleum Engineering",
        image: require("../../assets/careerlibrary/52.png"),
      },
      {
        title: "Environmental Engineering",
        image: require("../../assets/careerlibrary/53.png"),
      },
      {
        title: "Aerospace Engineering",
        image: require("../../assets/careerlibrary/54.png"),
      },
      {
        title: "CS, IT and Software Engineering",
        image: require("../../assets/careerlibrary/55.png"),
      },
      {
        title: "Biomedical and Biotechnology Engineering",
        image: require("../../assets/careerlibrary/56.png"),
      },
      {
        title: "Power Engineering",
        image: require("../../assets/careerlibrary/57.png"),
      },
      {
        title: "Robotics",
        image: require("../../assets/careerlibrary/60.png"),
      },
      {
        title: "Genetic Engineering",
        image: require("../../assets/careerlibrary/61.png"),
      },
      {
        title: "Artificial Intelligence and Machine Learning",
        image: require("../../assets/careerlibrary/62.png"),
      },
      {
        title: "Nanotechnology",
        image: require("../../assets/careerlibrary/63.png"),
      },
    ],
  },
  {
    id: "computer-application-it",
    title: "Computer Application & IT",
    image: require("../../assets/viewcareer/2.Computer-Applications-Cover.png"),
    subCareers: [
      {
        title: "Computer Application & IT",
        image: require("../../assets/viewcareer/2.Computer-Applications-Cover.png"),
      },
      {
        title: "Game Development",
        image: require("../../assets/viewcareer/Game_Development.png"),
      },
    ],
  },
  {
    id: "ethical-hacking",
    title: "Ethical Hacking",
    image: require("../../assets/viewcareer/3.Ethical-Hacking-Cover.png"),
    subCareers: [
      {
        title: "Ethical Hacking",
        image: require("../../assets/viewcareer/3.Ethical-Hacking-Cover.png"),
      },
    ],
  },
  {
    id: "aviation",
    title: "Aviation",
    image: require("../../assets/viewcareer/4.Aviation-Cover.png"),
    subCareers: [
      {
        title: "Pilot",
        image: require("../../assets/viewcareer/4A-Pilot.png"),
      },
      {
        title: "Aircraft Maintenance Enginee",
        image: require("../../assets/viewcareer/4B-Aircraft_Maintainence_Engineer.png"),
      },
      {
        title: "Air Traffic Controller",
        image: require("../../assets/viewcareer/Air_Traffic_Controller.png"),
      },
      {
        title: " Aircraft Maintenance Engineering",
        image: require("../../assets/viewcareer/Aircraft_Maintenance_Engineering.png"),
      },
    ],
  },
  {
    id: "architecture",
    title: "Architecture",
    image: require("../../assets/viewcareer/5.Architecture-Cover.png"),
    subCareers: [
      {
        title: "Architecture",
        image: require("../../assets/viewcareer/5.Architecture-Cover.png"),
      },
      {
        title: " Urban Planning ",
        image: require("../../assets/viewcareer/Urban_Planning.png"),
      },
    ],
  },
  {
    id: "merchant-navy",
    title: "Merchant Navy",
    image: require("../../assets/viewcareer/shutterstock_284337545.png"),
    subCareers: [
      {
        title: "Merchant Navy",
        image: require("../../assets/viewcareer/shutterstock_284337545.png"),
      },
    ],
  },
  {
    id: "physical-science",
    title: "Physical Science",
    image: require("../../assets/viewcareer/8.Physical-Science-Cover.png"),
    subCareers: [
      {
        title: "Astronomy",
        image: require("../../assets/viewcareer/8A-Astronomy.png"),
      },
      {
        title: "Meteorology",
        image: require("../../assets/viewcareer/8B-Meteorology.png"),
      },
      {
        title: "Geology",
        image: require("../../assets/viewcareer/8C-Geophysics_Geology.png"),
      },
      {
        title: " Forensic Science",
        image: require("../../assets/viewcareer/8E-Forensic_Science.png"),
      },
      {
        title: "Physics",
        image: require("../../assets/viewcareer/Physics.png"),
      },
    ],
  },
  {
    id: "life-science-environment",
    title: "Life Science & Environment",
    image: require("../../assets/viewcareer/9.Life-Science-Cover.png"),
    subCareers: [
      {
        title: "Biology",
        image: require("../../assets/viewcareer/9A-Biology.png"),
      },
      {
        title: "Botany",
        image: require("../../assets/viewcareer/9B-Botony.png"),
      },
      {
        title: "Zoology",
        image: require("../../assets/viewcareer/9C-Zoology.png"),
      },
      {
        title: "Physiology",
        image: require("../../assets/viewcareer/9E-Physiology.png"),
      },
      {
        title: "Biochemistry",
        image: require("../../assets/viewcareer/9F-BioChemistry.png"),
      },
      {
        title: "Genetics",
        image: require("../../assets/viewcareer/9G-Genetics.png"),
      },
    ],
  },
  {
    id: "law",
    title: "Law",
    image: require("../../assets/viewcareer/27.Law-Cover.png"),
    subCareers: [
      {
        title: "Law",
        image: require("../../assets/viewcareer/27.Law-Cover.png"),
      },
      {
        title: " Public Prosecution",
        image: require("../../assets/viewcareer/Public_Prosecution.png"),
      },
    ],
  },
];

const Viewallcareer = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
  const [isDropdownOpen3, setIsDropdownOpen3] = useState(false);
  const [selectedCareer, setSelectedCareer] = useState(null);

  const toggleDropdown = (career) => {
    if (selectedCareer === career) {
      setIsDropdownOpen(!isDropdownOpen);
    } else {
      setSelectedCareer(career);
      setIsDropdownOpen(true);
      setIsDropdownOpen2(false);
    }
  };

  const toggleDropdown2 = (career) => {
    if (selectedCareer === career) {
      setIsDropdownOpen2(!isDropdownOpen2);
    } else {
      setSelectedCareer(career);
      setIsDropdownOpen2(true);
      setIsDropdownOpen3(false);
    }
  };

  const toggleDropdown3 = (career) => {
    if (selectedCareer === career) {
      setIsDropdownOpen3(!isDropdownOpen3);
    } else {
      setSelectedCareer(career);
      setIsDropdownOpen3(true);
      setIsDropdownOpen(false);
    }
  };


  const renderCareer = (index) => (
    <div
      id={careers[index].id}
      className={`col-md-4 col-6 career__column js--careerColumn js--career-${index} js--careerMob-${index}`}
      onClick={() => toggleDropdown(careers[index])}
    >
      <div className="career">
        <div className="career_intro career_intro--main js--career">
          <div className="career__img-container">
            <img
              src={careers[index].image}
              alt=""
              className="w-full h-full object-cover"
            />
          </div>
          <div className="career__title">{careers[index].title}</div>
          <div className="arrow__up js--arrowUp"></div>
        </div>
      </div>
    </div>
  );

  const renderCareer2 = (index) => (
    <div
      id={careers[index].id}
      className={`col-md-4 col-6 career__column js--careerColumn js--career-${index} js--careerMob-${index}`}
      onClick={() => toggleDropdown2(careers[index])}
    >
      <div className="career">
        <div className="career_intro career_intro--main js--career">
          <div className="career__img-container">
            <img
              src={careers[index].image}
              alt=""
              className="w-full h-full object-cover"
            />
          </div>
          <div className="career__title">{careers[index].title}</div>
          <div className="arrow__up js--arrowUp"></div>
        </div>
      </div>
    </div>
  );

  const renderCareer3 = (index) => (
    <div
      id={careers[index].id}
      className={`col-md-4 col-6 career__column js--careerColumn js--career-${index} js--careerMob-${index}`}
      onClick={() => toggleDropdown3(careers[index])}
    >
      <div className="career">
        <div className="career_intro career_intro--main js--career">
          <div className="career__img-container">
            <img
              src={careers[index].image}
              alt=""
              className="w-full h-full object-cover"
            />
          </div>
          <div className="career__title">{careers[index].title}</div>
          <div className="arrow__up js--arrowUp"></div>
        </div>
      </div>
    </div>
  );

  const renderSubCareers = () => (
    <div className="bg-gray-100 w-[80vw] mx-auto shadow-2xl my-[20px] rounded-sm border-[1px] p-3">
      {selectedCareer && (
        <>
          <h1 className="text-xl text-center font-semibold mb-4">
            {selectedCareer.title}
          </h1>
          <p className="text-md mt-[20px] mb-[40px] ">
            {selectedCareer.text}
          </p>
          <div className="grid grid-cols-3 mt-8 mb-8 mx-auto">
            {selectedCareer.subCareers.map((subCareer, subIndex) => (
              <div
                key={subIndex}
                className=" w-[30vw] h-[15vw] mb-[40px] ml-10 mt-10"
              >
                <img
                  src={subCareer.image}
                  alt=""
                  className="w-[20vw] object-cover"
                />
                <p className="text-md font-semibold mt-[20px] mb-[40px] ">
                  {subCareer.title}
                </p>
                <p className="text-md font-semibold mt-[20px] mb-[40px] ">
                  {subCareer.text}
                </p>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );

  return (
    <div className="mt-[100px] max-w-[1280px] mx-auto">
      <div className="row careers gap-10 mx-[20px] js--careers">
        {renderCareer(0)}
        {renderCareer(1)}
        {renderCareer(2)}
      </div>
      {isDropdownOpen && selectedCareer && renderSubCareers()}
      <div className="row careers gap-10 mx-[20px] js--careers">
        {renderCareer2(3)}
        {renderCareer2(4)}
        {renderCareer2(5)}
      </div>
      {isDropdownOpen2 && selectedCareer && renderSubCareers()}
      <div className="row careers gap-10 mx-[20px] js--careers">
        {renderCareer3(6)}
        {renderCareer3(7)}
        {renderCareer3(8)}
      </div>
    </div>
  );
};

export default Viewallcareer;
