import React from "react";

const Animationandgraphic = () => {
  return (
    <div className=" mt-[100px]">
      <div className="w-full grid-cols-1 flex items-center justify-center">
        <div className="w-full h-[300px] bg-[#64964d]">
          <h2 className="text-white text-3xl text-center mt-[120px]">
            Career Library
          </h2>
        </div>
      </div>
      <div className="mx-[100px] text-justify">
        <h3 className="text-2xl mt-10 ">Animation & Graphics</h3>
        <hr ></hr>
        <p className=" mt-6 text-md">
          Animation and graphics is the combination of art with technology to
          produce movies, websites, digital content, video games etc. This
          domain requires having basic knowledge of fine arts along with ability
          to use computers and technology for creating digital special effects,
          producing 2D and 3D programs, creating graphics for websites, logos,
          illustrations etc. UI/UX designers are at the helm of this work. This
          can be an ideal job for people who have a creative bent of mind and
          are also tech savvy.
          <br></br>
          <p className="mt-4">
            Along with the right education in the field, one should have an
            attitude and passion to excel in animation graphics.
          </p>
          <br></br>
          <p className="mt-4">
            Bachelor's degree in graphic designing, graphic arts or visual
            communication is available in various universities and institutes.
            One can also pursue bachelors in fine arts and then apply for a
            certificate or diploma course in animation. If one's interest lies
            in UI/UX design, then computer science is an important subject for
            them.
          </p>
          <br></br>
          <p className="mt-4">
            Job opportunities are available in Top Design Firms, Adverstising
            Agencies, MNCs, Website and App Development Agencies, Electronic
            Industry, Entertainment Industry amongst others.
          </p>
        </p>
      </div>
      <p className="mt-6 text-md mx-[100px]">
        Trending Field: Character Animator, UI/ UX Designers, Graphic Designers
      </p>
      <div className="flex flex-wrap mt-8 mx-[100px]">
        <div className="items-left w-[400px] h-[200px] mb-[40px] ">
          <img
            src={require("../../assets/careerlibrary/5.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md  font-semibold mt-[20px] mb-[40px] text-center">UI Design</p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px] ml-10">
          <img
            src={require("../../assets/careerlibrary/6.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">UX Design</p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px] ml-10">
          <img
            src={require("../../assets/careerlibrary/7.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">
            Graphic Design
          </p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[80px]  mt-8">
          <img
            src={require("../../assets/careerlibrary/8.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px]  text-center">Web Desgin</p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[80px] ml-10 mt-8">
          <img
            src={require("../../assets/careerlibrary/9.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px]  text-center">Cartoonist</p>
        </div>
      </div>
    </div>
  );
};

export default Animationandgraphic;
