import React from "react";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import Commerceaccount from "../Components/Commerceaccount/Commerceaccount";


export const CommerceAccount = () => {
  return (
    <div className="overflow-hidden">
      <Header />
       <Commerceaccount/>
      <Footer />
    </div>
  );
};
