import React from "react";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import BannerInstitute from "../Components/Banner/BannerInstitute";
import Forinstitutes from "../Components/Forinstitutes/Forinstitutes";
import OurPartner from "../Components/OurPartner/OurPartner";
import Testimonials from "../Components/Testimonials/Testimonials";


export const ForInstitute = () => {
  return (
    <div className="overflow-hidden">
      <Header />
      <BannerInstitute/>
      <Forinstitutes/>
      <OurPartner/>
      <Testimonials/>
      <Footer />
    </div>
  );
};
