import React from "react";
import Header from "../Components/Header/Header";
import Banner from "../Components/Banner/Banner";
import Whychooseus from "../Components/Whychooseus/Whychooseus";
import Student from "../Components/Student/Student";
import TopColleges from "../Components/TopColleges/TopColleges";
import Footer from "../Components/Footer/Footer";
import Sessions from "../Components/Sessions/Sessions";
import SuccessStory from "../Components/SuccessStory/SuccessStory";

export const HomePage = () => {
  return (
    <div className="overflow-hidden">
      <Header />
      <Banner />
      <Whychooseus />
      <Student />
      <TopColleges />
     <SuccessStory/>
      <Sessions /> 
      <Footer />
    </div>
  );
};
