import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HomePage } from "./Pages/HomePage";
import { ForStudent } from "./Pages/ForStudent";
import { ForInstitute } from "./Pages/ForInstitute";
import { ContactUs } from "./Pages/ContactUs";
import { ActuarialScience } from "./Pages/ActuarialScience";
import { AppliedArt } from "./Pages/AppliedArt";
import { AnimationAndGraphic } from "./Pages/AnimationAndGraphic";
import { Architecturepage } from "./Pages/Architecturepage";
import { Aviationpage } from "./Pages/Aviationpage";
import { CabinCrew } from "./Pages/CabinCrew";
import { CivilService } from "./Pages/CivilService";
import { CommerceAccount } from "./Pages/CommerceAccount";
import { ComputerApplication } from "./Pages/ComputerApplication";
import { Defensepage } from "./Pages/Defensepage";
import { Designpage } from "./Pages/Designpage";
import { Economicpage } from "./Pages/Economicpage";
import { Engineeringpage } from "./Pages/Engineeringpage";
import { ViewAllCareers } from "./Pages/ViewAllCareers";



function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/students" element={<ForStudent />} />
        <Route path="/institutes" element={<ForInstitute />} />
        <Route path="/contacts" element={<ContactUs />} />
        <Route path="/actuarials" element={<ActuarialScience />} />
        <Route path="/applieds" element={<AppliedArt />} />
        <Route path="/animations" element={<AnimationAndGraphic />} />
        <Route path="/architectures" element={<Architecturepage />} />
        <Route path="/aviations" element={<Aviationpage />} />
        <Route path="/cabins" element={<CabinCrew />} />
        <Route path="/civils" element={<CivilService />} />
        <Route path="/commerces" element={<CommerceAccount />} />
        <Route path="/applications" element={<ComputerApplication />} />
        <Route path="/defenses" element={<Defensepage />} />
        <Route path="/designs" element={<Designpage />} />
        <Route path="/economics" element={<Economicpage />} />
        <Route path="/engineerings" element={<Engineeringpage />} />
        <Route path="/allcareers" element={<ViewAllCareers />} />
      </Routes>
    </Router>
  );
}

export default App;
