import React from "react";
import "./whychooseus.css";

const Whychooseus = () => {
  return (
    <div className="flex items-center justify-center ">
      <div>
        <div className="lg:text-3xl text-md font-bold text-black text-center mt-[100px]">
          <h2>Why Choose Guide4Careers</h2>
        </div>
        <div className=" mx-auto chooseus  mt-10">
        <div className="flex-none shadow-xl h-[270px] w-[300px] mx-auto my-4 border border-2px rounded-2xl hover:scale-110 duration-100 hover:bg-[#f3f0f0] flex flex-col items-center">
            <img
              src={require("../../assets/success/xyz.jpg")}
              alt=""
              className="w-24 h-28 object-cover rounded-full mx-auto  px-2 py-2"
            />
            <p className="text-md text-black px-5 py-3">
              Enable students to identify their best-fit career with our
              world-class career assessment and personalised guidance.
            </p>
          </div>
        <div className="flex-none shadow-xl h-[270px] w-[300px] mx-auto my-4 border border-2px rounded-2xl hover:scale-110 duration-100 hover:bg-[#f3f0f0] flex flex-col items-center">
            <img
              src={require("../../assets/success/543.png")}
              alt=""
              className="w-26 h-28 object-cover rounded-full mx-auto px-2 py-2"
            />
            <p className="text-md text-black px-5 py-3">
              Empower students to learn all about the professional world with
              virtual career simulations, exhaustive career library, career
              blogs and vlogs.
            </p>
          </div>
        <div className="flex-none shadow-xl h-[270px] w-[300px] mx-auto my-4 border border-2px rounded-2xl hover:scale-110 duration-100 hover:bg-[#f3f0f0] flex flex-col items-center">
            <img
              src={require("../../assets/success/867.jpg")}
              alt=""
              className="w-28 h-28 object-cover rounded-full mx-auto px-2 py-2 "
            />
            <p className="text-md text-black px-5 py-3">
              Pave student’s way to their dream college with our end-to-end
              college application guidance, scholarship drive and corporate
              internship program.
            </p>
          </div>
        <div className="flex-none shadow-xl h-[270px] w-[300px] mx-auto my-4 border border-2px rounded-2xl hover:scale-110 duration-100 hover:bg-[#f3f0f0] flex flex-col items-center">
            <img
              src={require("../../assets/success/gh.jpg")}
              alt=""
              className="w-24 h-28 object-cover rounded-full mx-auto px-2 py-2"
            />
            <p className="text-md text-black px-5 py-3 ">
              Enable schools in creating a career guidance ecosystem in sync
              with the vision of New Education Policy
            </p>
          </div>
        <div className="flex-none shadow-xl h-[270px] w-[300px] mx-auto my-4 border border-2px rounded-2xl hover:scale-110 duration-100 hover:bg-[#f3f0f0] flex flex-col items-center">
            <img
              src={require("../../assets/success/def.jpg")}
              alt=""
              className="w-24 h-28 object-cover rounded-full mx-auto px-2 py-2"
            />
            <p className="text-md text-black px-5 py-3">
              Empower educators to become International Certified Career Coaches
              and build a career in career guidance & counselling
            </p>
          </div>
        <div className="flex-none shadow-xl h-[270px] w-[300px] mx-auto my-4 border border-2px rounded-2xl hover:scale-110 duration-100 hover:bg-[#f3f0f0] flex flex-col items-center">
            <img
              src={require("../../assets/success/abc.jpg")}
              alt=""
              className="w-24 h-28 object-cover rounded-full mx-auto px-2 py-2"
            />
            <p className="text-md text-black px-5 py-3">
              Revolutionary assessment platform and technology driven career
              guidance solutions for educators to boost their career guidance &
              counselling practice
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Whychooseus;


