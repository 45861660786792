import React from "react";
import { NavLink } from "react-router-dom";
import { IoMdCall, IoMdMail, IoMdPin } from "react-icons/io";
import logo from "../../assets/logo/logo.png";

const Footer = () => {
  const updatedDate = new Date().getFullYear();

  return (
    <footer className="bg-[#f3f0f0] overflow-hidden">
      <div className="mx-auto max-w-screen-2xl px-4 pb-6 pt-16 sm:px-6 lg:px-8 lg:pt-24">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
          <div className="grid justify-center lg:col-span-1">
            <div className="flex flex-col items-center lg:items-start gap-6 lg:justify-start text-[#063970]">
              <NavLink to="/">
                <div className="mb-[-12px] px-8">
                  <img
                    src={logo}
                    alt="logo"
                    className="max-h-[150px] max-w-[150px] px-4 pb-2"
                  />
                </div>
              </NavLink>
              <p className="lg:text-justify md:text-[100%] text-[80%] hover:text-gray-600 text-center leading-relaxed text-black lg:max-w-xs md:w-[450px] px-10">
                A Guide4Careers acts as a compass, providing valuable insights
                and direction for those seeking professional fulfillment. It
                begins with self-reflection, encouraging individuals to identify
                their strengths, interests, and aspirations. Setting
                well-defined goals and continually developing relevant skills.
                Networking and seeking mentorship offer opportunities for
                growth.
              </p>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-8 sm:grid-cols-3 md:grid-cols-3 lg:col-span-2">
            <div className="text-center ">
              <p className="md:text-[120%] text-[90%] text-black hover:text-gray-600 font-medium">
                Company
              </p>
              <ul className="mt-8 space-y-4 text-[80%] md:text-[100%]">
                <li>
                  <NavLink
                    className="text-black transition hover:text-gray-400"
                    to="/"
                  >
                    Home
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    className="text-black transition hover:text-gray-400"
                    to="/students"
                  >
                    For Students
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    className="text-black transition hover:text-gray-400"
                    to="/institutes"
                  >
                    For Institutes
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    className="text-black transition hover:text-gray-400"
                    to="/resources"
                  >
                    Resource Treasure
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="text-black transition hover:text-gray-400"
                    to="/contacts"
                  >
                    Contact Us
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="text-center ">
              <p className="md:text-[120%] text-[90%] hover:text-gray-600  text-black whitespace-nowrap font-medium">
                Whom We Serve
              </p>
              <ul className="mt-8 space-y-4 text-[80%] md:text-[100%]">
                <li>
                  <p className="text-black transition hover:text-gray-400 whitespace-nowrap">
                    School Students
                  </p>
                </li>

                <li>
                  <p className="text-black transition hover:text-gray-400 whitespace-nowrap">
                    College Students
                  </p>
                </li>

                <li>
                  <p className="text-black  transition hover:text-gray-400 whitespace-nowrap">
                    Educational Institutions
                  </p>
                </li>

                <li>
                  <p className="text-black transition  hover:text-gray-400 whitespace-nowrap">
                    Private Schools
                  </p>
                </li>
                <li>
                  <p className="text-black transition  hover:text-gray-400 whitespace-nowrap">
                    Private Colleges
                  </p>
                </li>
              </ul>
            </div>
            <div className="text-center max-sm:hidden sm:text-left">
              <p className="md:text-[120%] text-[90%] hover:text-gray-600 font-medium text-black">
                Contact Us
              </p>
              <ul className="mt-8 space-y-4 text-[80%] md:text-[100%]">
                <li className="flex items-center hover:text-gray-400 text-black gap-1.5 justify-start">
                  <a
                    href="mailto:info@templeoflearning.com"
                    className="gap-1.5 flex py-4"
                  >
                    <IoMdMail />
                    <h>info@templeoflearning.com</h>
                  </a>
                </li>
                <li className="flex  hover:text-gray-400 text-black gap-1.5 justify-start">
                  <IoMdCall />
                  <a
                    href="tel:+91-98100 33495"
                    className="gap-1.5 flex mt-[-13px]"
                  >
                    <span>+91-98100 33495</span>
                  </a>
                </li>
                <li className="flex  hover:text-gray-400 text-black  justify-start">
                  <a
                    href="tel:+011-45 019 365"
                    className=" flex ml-6 mt-[-20px]"
                  >
                    <span>+011-45 019 365</span>
                  </a>
                </li>

                <li className="flex  hover:text-gray-400 text-black gap-1.5 justify-start text-start">
                  <IoMdPin />
                  <h className="gap-0.5 flex-1">
                    Head Office - L-3, Kanchanjunga Building Barakhamba Road,
                    Connaught Place
                  </h>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="sm:hidden block text-center mt-[6vw] ">
          <h className="text-[110%] font-medium text-black">Contact Us</h>
          <ul className="mt-8  space-y-4 text-[80%]">
            <li className="flex items-center text-black justify-center gap-1.5 ">
              <a href="mailto:info@templeoflearning.com.com">
                <h className="flex items-center text-black justify-center gap-1.5 ">
                  <IoMdMail />
                  <span className="flex-1 hover:text-gray-400 text-black">
                    info@templeoflearning.com
                  </span>
                </h>
              </a>
            </li>
            <li className="flex items-center text-black justify-center gap-1.5 ">
              <a href="tel:+91-98100 33495">
                <h className="flex items-center text-black justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end">
                  <IoMdCall />
                  <span className="flex-1 hover:text-gray-400 text-black mt-[-10px]">
                    +91-98100 33495
                  </span>
                </h>
              </a>
            </li>
            <li className="flex items-center text-black justify-center gap-1.5 ">
              <a href="tel:+011-45 019 365" className=" flex ml-6 mt-[-20px]">
                <span>+011-45 019 365</span>
              </a>
            </li>
            <li className="flex mx-auto w-[300px] relative text-ellipsis text-black justify-center items-center">
              <h className="flex  text-black justify-center gap-1.5 ">
                <IoMdPin className="relative top-[3px] left-[16px]" />
                <span className="flex-1 hover:text-gray-400 text-black">
                  Head Office - L-3, Kanchanjunga Building Barakhamba Road,
                  Connaught Place
                </span>
              </h>
            </li>
          </ul>
        </div>
        <div className="mt-12 border-t border-black pt-6 ml-[20px] mr-[20px]">
          <div className="text-center sm:flex justify-between sm:text-left">
            <p className="block text-black sm:inline">All rights reserved.</p>
            <p className="flex sm:flex-row flex-col  gap-[10px] max-md:justify-center  md:text-[100%] text-[90%] ">
              <NavLink
                className=" whitespace-nowrap  text-[#063970] underline transition hover:text-blue-800"
                to="/"
              >
                Terms & Conditions
              </NavLink>
              <p className="md:block hidden  text-black sm:inline"> | </p>
              <NavLink
                className="whitespace-nowrap text-[#063970] underline transition hover:text-blue-800"
                to="/"
              >
                Privacy Policy
              </NavLink>
            </p>
            <p className="mt-4 md:text-[100%] text-[90%] text-black sm:order-first sm:mt-0">
              &copy; {updatedDate} Guide4Careers
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
