// import React from "react";

// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import "swiper/css/pagination";
// import "swiper/css/navigation";
// import "./successstory.css";

// import { Pagination, Navigation } from "swiper/modules";
// const SuccessStory = () => {
//   return (
//     <>
//       <div className="overflow-hidden md:hidden max-md:block">
//         <h2 className="flex justify-center lg:text-4xl text-2xl font-bold mt-8 mb-[80px] ">
//           Success Stories !
//         </h2>
//         <Swiper
//           grabCursor={true}
//           centeredSlides={true}
//           slidesPerView={"auto"}
//           style={{
//             "--swiper-navigation-color": "black",
//             "--swiper-pagination-color": "#fff",
//           }}
//           pagination={true}
//           navigation={true}
//           className="Swiper2 max-w-[1280px] overflow-hidden"
//           modules={[Pagination, Navigation]}
//         >
//           <SwiperSlide>
//             <div className="col-span-1 shadow-2xl  lg:h-[400px] h-[450px] my-4   mr-[20px] ml-[30px] rounded-3xl  ">
//               <div className="text-center font-medium">
//                 <img
//                   className="relative mr-auto ml-6 my-6"
//                   src={require("../../assets/success/success2.webp")}
//                   alt=""
//                 />
//                 <p className=" text-[blue]  lg:mt-[-90px] mt-[-30px] ml-[60px] text-sm">
//                   Tapan Mandal
//                 </p>
//                 <p className="lg:ml-[80px] text-sm ">BBA, Depauw University</p>
//                 <h5 className="font-bold  lg:my-[60px] my-[40px] lg:ml-[-40px] md:ml-[-130px] text-sm">
//                   Scholarship Received -
//                 </h5>
//                 <p className="text-sm lg:mt-[-30px] mt-[-30px] mr-8 ml-8 text-justify ">
//                   I had been looking for assistance with my applications for a
//                   long time and spoke to many counselors and agencies. But after
//                   meeting with iDC counselors, I knew that I had to go ahead
//                   with them.
//                 </p>
//               </div>
//             </div>
//           </SwiperSlide>
//           <SwiperSlide>
//             <div className="col-span-1 shadow-2xl lg:h-[400px] h-[450px] my-4  mr-[20px] ml-[30px] rounded-3xl">
//               <div className="text-center font-medium">
//                 <img
//                   className="relative mr-auto ml-6 my-6"
//                   src={require("../../assets/success/success1.webp")}
//                   alt=""
//                 />
//                 <p className=" text-[blue] lg:mt-[-90px] mt-[-30px] ml-[60px] text-sm">
//                   Sita Ramaswami
//                 </p>
//                 <p className="lg:ml-[170px] text-sm">
//                   Class 10, Gopalan International School
//                 </p>
//                 <h5 className="font-bold my-[30px] lg:my-[60px] lg:ml-[-40px] md:ml-[-4px] text-sm">
//                   Open discussion about my children’s opinions!
//                 </h5>
//                 <p className="text-sm lg:mt-[-30px] mt-[-20px] mr-8 ml-8 text-justify">
//                   Along with psychometric assessment, personalized dashboard
//                   provided great information for both my children. With the
//                   career report & one-on-one counseling,there was an open
//                   discussion about my children’s opinions and choices.
//                 </p>
//               </div>
//             </div>
//           </SwiperSlide>
//           <SwiperSlide>
//             <div className="col-span-1 shadow-2xl lg:h-[400px] h-[450px] my-4  mr-[20px] ml-[30px] rounded-3xl">
//               <div className="text-center font-medium">
//                 <img
//                   className="relative mr-auto ml-6 my-6"
//                   src={require("../../assets/success/success3.webp")}
//                   alt=""
//                 />
//                 <p className=" text-[blue] lg:mt-[-90px] mt-[-30px] ml-[60px] text-sm">
//                   Kritika Verma
//                 </p>
//                 <p className="lg:ml-[80px] text-sm">
//                   2nd Year, Hansraj College
//                 </p>
//                 <h5 className="font-bold lg:my-[60px] my-[40px] lg:ml-[-40px] md:ml-[-4px]">
//                   Understood best career opportunities for me
//                 </h5>
//                 <p className="text-sm mt-[-30px] mr-8 ml-8 text-justify">
//                   Earlier I was too confused regarding my career options. There
//                   were too many career options and I didn’t know how to evaluate
//                   betwen them and choose one. In my first session, the
//                   counsellor helped me understand some of the best career
//                   opportunities.
//                 </p>
//               </div>
//             </div>
//           </SwiperSlide>
//           <SwiperSlide>
//             <div className="col-span-1 shadow-2xl lg:h-[400px] h-[450px] my-4 mr-[20px] ml-[30px] rounded-3xl">
//               <div className="text-center font-medium">
//                 <img
//                   className="relative mr-auto ml-6 my-6"
//                   src={require("../../assets/success/success4.webp")}
//                   alt=""
//                 />
//                 <p className=" text-[blue] lg:mt-[-90px] mt-[-30px] ml-[60px] text-sm">
//                   Anirban Saren
//                 </p>
//                 <p className="lg:ml-[80px] text-sm">
//                   3rd Year, Asutosh College
//                 </p>
//                 <h5 className="font-bold lg:my-[60px] my-[40px] lg:ml-[-40px] md:ml-[-4px]">
//                   Assessment and Counseling worth the money!
//                 </h5>
//                 <p className="text-sm lg:mt-[-30px] mt-[-30px] mr-8 ml-8 text-justify">
//                   The assessment and career counselling video call was worth the
//                   money. My counselor guided me through the best career option
//                   for me and my future. she accordingly gave a very practical
//                   solution which is also suited for my personality, interests
//                   and aptitude.
//                 </p>
//               </div>
//             </div>
//           </SwiperSlide>
//         </Swiper>
//       </div>
//       <div className="overflow-hidden max-md:hidden md:block">
//         <h2 className="flex justify-center lg:text-4xl text-2xl font-bold mt-8 mb-[80px] ">
//           Success Stories !
//         </h2>
//         <Swiper
//           effect={"coverflow"}
//           grabCursor={true}
//           centeredSlides={true}
//           slidesPerView={"auto"}
//           coverflowEffect={{
//             rotate: 50,
//             stretch: 0,
//             depth: 100,
//             modifier: 1,
//             slideShadows: true,
//           }}
//           style={{
//             "--swiper-navigation-color": "black",
//             "--swiper-pagination-color": "#fff",
//           }}
//           pagination={true}
//           navigation={true}
//           className="Swiper2 max-w-[1180px] overflow-hidden"
//           modules={[Pagination, Navigation]}
//         >
//           <SwiperSlide>
//             <div className="col-span-1 shadow-2xl  lg:h-[400px] h-[450px] my-4  mx-auto rounded-3xl  ">
//               <div className="text-center font-medium">
//                 <img
//                   className="relative mr-auto ml-6 my-6"
//                   src={require("../../assets/success/success2.webp")}
//                   alt=""
//                 />
//                 <p className=" text-[blue]  lg:mt-[-90px] mt-[-30px] ml-[60px] text-sm">
//                   Tapan Mandal
//                 </p>
//                 <p className="lg:ml-[80px] text-sm ">BBA, Depauw University</p>
//                 <h5 className="font-bold  lg:my-[60px] my-[40px] lg:ml-[-40px] md:ml-[-130px] text-sm">
//                   Scholarship Received -
//                 </h5>
//                 <p className="text-sm lg:mt-[-30px] mt-[-30px] mr-8 ml-8 text-justify ">
//                   I had been looking for assistance with my applications for a
//                   long time and spoke to many counselors and agencies. But after
//                   meeting with iDC counselors, I knew that I had to go ahead
//                   with them.
//                 </p>
//               </div>
//             </div>
//           </SwiperSlide>
//           <SwiperSlide>
//             <div className="col-span-1 shadow-2xl lg:h-[400px] h-[450px] my-4  mr-[20px] ml-[30px] rounded-3xl">
//               <div className="text-center font-medium">
//                 <img
//                   className="relative mr-auto ml-6 my-6"
//                   src={require("../../assets/success/success1.webp")}
//                   alt=""
//                 />
//                 <p className=" text-[blue] lg:mt-[-90px] mt-[-30px] ml-[60px] text-sm">
//                   Sita Ramaswami
//                 </p>
//                 <p className="lg:ml-[170px] text-sm">
//                   Class 10, Gopalan International School
//                 </p>
//                 <h5 className="font-bold my-[30px] lg:my-[60px] lg:ml-[-40px] md:ml-[-4px] text-sm">
//                   Open discussion about my children’s opinions!
//                 </h5>
//                 <p className="text-sm lg:mt-[-30px] mt-[-20px] mr-8 ml-8 text-justify">
//                   Along with psychometric assessment, personalized dashboard
//                   provided great information for both my children. With the
//                   career report & one-on-one counseling,there was an open
//                   discussion about my children’s opinions and choices.
//                 </p>
//               </div>
//             </div>
//           </SwiperSlide>
//           <SwiperSlide>
//             <div className="col-span-1 shadow-2xl lg:h-[400px] h-[450px] my-4  mr-[20px] ml-[30px] rounded-3xl">
//               <div className="text-center font-medium">
//                 <img
//                   className="relative mr-auto ml-6 my-6"
//                   src={require("../../assets/success/success3.webp")}
//                   alt=""
//                 />
//                 <p className=" text-[blue] lg:mt-[-90px] mt-[-30px] ml-[60px] text-sm">
//                   Kritika Verma
//                 </p>
//                 <p className="lg:ml-[80px] text-sm">
//                   2nd Year, Hansraj College
//                 </p>
//                 <h5 className="font-bold lg:my-[60px] my-[40px] lg:ml-[-40px] md:ml-[-4px]">
//                   Understood best career opportunities for me
//                 </h5>
//                 <p className="text-sm mt-[-30px] mr-8 ml-8 text-justify">
//                   Earlier I was too confused regarding my career options. There
//                   were too many career options and I didn’t know how to evaluate
//                   betwen them and choose one. In my first session, the
//                   counsellor helped me understand some of the best career
//                   opportunities.
//                 </p>
//               </div>
//             </div>
//           </SwiperSlide>
//           <SwiperSlide>
//             <div className="col-span-1 shadow-2xl lg:h-[400px] h-[450px] my-4 mr-[20px] ml-[30px] rounded-3xl">
//               <div className="text-center font-medium">
//                 <img
//                   className="relative mr-auto ml-6 my-6"
//                   src={require("../../assets/success/success4.webp")}
//                   alt=""
//                 />
//                 <p className=" text-[blue] lg:mt-[-90px] mt-[-30px] ml-[60px] text-sm">
//                   Anirban Saren
//                 </p>
//                 <p className="lg:ml-[80px] text-sm">
//                   3rd Year, Asutosh College
//                 </p>
//                 <h5 className="font-bold lg:my-[60px] my-[40px] lg:ml-[-40px] md:ml-[-4px]">
//                   Assessment and Counseling worth the money!
//                 </h5>
//                 <p className="text-sm lg:mt-[-30px] mt-[-30px] mr-8 ml-8 text-justify">
//                   The assessment and career counselling video call was worth the
//                   money. My counselor guided me through the best career option
//                   for me and my future. she accordingly gave a very practical
//                   solution which is also suited for my personality, interests
//                   and aptitude.
//                 </p>
//               </div>
//             </div>
//           </SwiperSlide>
//         </Swiper>
//       </div>
//     </>
//   );
// };

// export default SuccessStory;

import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./successstory.css";
import { Pagination, Navigation, HashNavigation } from "swiper/modules";

export default function App() {
  const swiperRef = useRef(null);

  const nextSlide = () => {
    if (swiperRef.current && swiperRef.current.slideNext) {
      swiperRef.current.slideNext();
    }
  };

  const prevSlide = () => {
    if (swiperRef.current && swiperRef.current.slidePrev) {
      swiperRef.current.slidePrev();
    }
  };

  return (
    <div className="overflow-hidden mx-auto">
      <h2 className="flex justify-center lg:text-4xl text-1xl font-bold mt-8 ">
        Our Success Stories !
      </h2>
      <div className="relative flex justify-end gap-4 px-20 mt-8 ">
        <button className="successbtn" onClick={prevSlide}>
          ‹
        </button>
        <button className="successbtn " onClick={nextSlide}>
          ›
        </button>
      </div>

      <Swiper
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        navigation={false}
        modules={[Pagination, Navigation, HashNavigation]}
        className="mySwiper"
        onSwiper={(swiper) => (swiperRef.current = swiper)}
      >
        <SwiperSlide data-hash="slide1">
          <div className="col-span-1 shadow-2xl  lg:h-[400px] h-[450px] my-4  mr-[20px] ml-[30px] rounded-3xl">
            <div className="text-center font-medium">
              <img
                className="relative mr-auto ml-6 my-6"
                src={require("../../assets/success/success2.webp")}
                alt=""
              />
              <p className=" text-[blue]  lg:mt-[-90px] mt-[-30px] ml-[60px] text-sm">
                Tapan Mandal
              </p>
              <p className="lg:ml-[80px] text-sm ">BBA, Depauw University</p>
              <h5 className="font-bold  lg:my-[60px] my-[40px] lg:ml-[-490px] md:ml-[-130px] text-sm">
                Scholarship Received -
              </h5>
              <p className="text-sm lg:mt-[-30px] mt-[-30px] mr-8 ml-8 text-justify ">
                I had been looking for assistance with my applications for a
                long time and spoke to many counselors and agencies. But after
                meeting with iDC counselors, I knew that I had to go ahead with
                them.
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide data-hash="slide1">
          <div className="col-span-1 shadow-2xl lg:h-[400px] h-[450px] my-4  mr-[20px] ml-[30px] rounded-3xl">
            <div className="text-center font-medium">
              <img
                className="relative mr-auto ml-6 my-6"
                src={require("../../assets/success/success1.webp")}
                alt=""
              />
              <p className=" text-[blue] lg:mt-[-90px] mt-[-30px] ml-[60px] text-sm">
                Sita Ramaswami
              </p>
              <p className="lg:ml-[170px] text-sm">
                Class 10, Gopalan International School
              </p>
              <h5 className="font-bold my-[30px] lg:my-[60px] lg:ml-[-290px] md:ml-[-4px] text-sm">
                Open discussion about my children’s opinions!
              </h5>
              <p className="text-sm lg:mt-[-30px] mt-[-20px] mr-8 ml-8 text-justify">
                Along with psychometric assessment, personalized dashboard
                provided great information for both my children. With the career
                report & one-on-one counseling,there was an open discussion
                about my children’s opinions and choices.
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide data-hash="slide1">
          <div className="col-span-1 shadow-2xl lg:h-[400px] h-[450px] my-4  mr-[20px] ml-[30px] rounded-3xl">
            <div className="text-center font-medium">
              <img
                className="relative mr-auto ml-6 my-6"
                src={require("../../assets/success/success3.webp")}
                alt=""
              />
              <p className=" text-[blue] lg:mt-[-90px] mt-[-30px] ml-[60px] text-sm">
                Kritika Verma
              </p>
              <p className="lg:ml-[80px] text-sm">2nd Year, Hansraj College</p>
              <h5 className="font-bold lg:my-[60px] my-[40px] lg:ml-[-290px] md:ml-[-4px]">
                Understood best career opportunities for me
              </h5>
              <p className="text-sm mt-[-30px] mr-8 ml-8 text-justify">
                Earlier I was too confused regarding my career options. There
                were too many career options and I didn’t know how to evaluate
                betwen them and choose one. In my first session, the counsellor
                helped me understand some of the best career opportunities.
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide data-hash="slide1">
          <div className="col-span-1 shadow-2xl lg:h-[400px] h-[450px] my-4 mr-[20px] ml-[30px] rounded-3xl">
            <div className="text-center font-medium">
              <img
                className="relative mr-auto ml-6 my-6"
                src={require("../../assets/success/success4.webp")}
                alt=""
              />
              <p className=" text-[blue] lg:mt-[-90px] mt-[-30px] ml-[60px] text-sm">
                Anirban Saren
              </p>
              <p className="lg:ml-[80px] text-sm">3rd Year, Asutosh College</p>
              <h5 className="font-bold lg:my-[60px] my-[40px] lg:ml-[-290px] md:ml-[-4px]">
                Assessment and Counseling worth the money!
              </h5>
              <p className="text-sm lg:mt-[-30px] mt-[-30px] mr-8 ml-8 text-justify">
                The assessment and career counselling video call was worth the
                money. My counselor guided me through the best career option for
                me and my future. she accordingly gave a very practical solution
                which is also suited for my personality, interests and aptitude.
              </p>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
