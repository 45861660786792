import React from "react";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import Civilservice from "../Components/Civilservice/Civilservice";


export const CivilService = () => {
  return (
    <div className="overflow-hidden">
      <Header />
      <Civilservice/>
      <Footer />
    </div>
  );
};
