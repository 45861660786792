import React from "react";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import Forstudents from "../Components/Forstudents/Forstudents";
import BannerStudent from "../Components/Banner/BannerStudent";
import Works from "../Components/Works/Works";
import Planpricing from "../Components/PlanPricing/Planpricing";
import Success from "../Components/Success/Success";


export const ForStudent = () => {
  return (
    <div className="overflow-hidden">
      <Header />
      <BannerStudent/>
      <Forstudents />
      <Works/>
      <Success/>
      <Planpricing/>
      <Footer />
    </div>
  );
};
