import React from "react";

const Desgin = () => {
  return (
    <div className=" mt-[100px]">
      <div className="w-full grid-cols-1 flex items-center justify-center">
        <div className="w-full h-[300px] bg-[#64964d]">
          <h2 className="text-white text-3xl text-center mt-[120px]">
            Career Library
          </h2>
        </div>
      </div>
      <div className="mx-[100px] text-justify">
        <h3 className="text-2xl mt-10 ">Design</h3>
        <hr ></hr>
        <p className=" mt-6 text-md">
          Career in design is for people who have a creative outlook and are
          able to form innovative connections between colors, shape, materials
          and textures. Designers are required in nearly every field whether in
          designing cars, clothes, buildings, products, logos, theatre sets,
          merchandise sets, landscapes etc. While most indulge in computer-aided
          designs, a basic fine art is also intrinsic to designing.
          <br></br>
          <p className="mt-4">
            Design has wide variety of sub fields including graphic designing,
            fashion designing, interior designing, web designing, set designing,
            industrial designing, visual merchandise designing etc. Each of
            these categories requires a domain specialization. One can select
            their area of specialization on the basis of their interest, skill
            and aptitude. Most institutes have an entrance exam for admission
            and competition for the premier institutes like NID and NIFT is
            quite high.
          </p>
          <br></br>
          <p className="mt-4">
            In order to build a career in design one should have a bachelor's or
            master's degree in the particular field.
          </p>
          <br></br>
          <p className="mt-4">
            Designers can find jobs in Fashion Houses, Media Houses, Automobile
            Industry, Technology Firms, Web Designing Firms etc. A lot of
            designers also pursue their career as a Freelancer.
          </p>
        </p>
      </div>
      <p className="mt-6 text-md mx-[100px]">
        Trending Field: Graphic Design, Fashion Design, Animation & Graphics
      </p>
      <div className="flex flex-wrap mt-8 mx-[100px] mb-8">
        <div className="items-left w-[400px] h-[200px] mb-[40px] ">
          <img
            src={require("../../assets/careerlibrary/38.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">
            Fashion Design
          </p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px] ml-10 ">
          <img
            src={require("../../assets/careerlibrary/39.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">
            Textile and Apparel Design
          </p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px] ml-10 ">
          <img
            src={require("../../assets/careerlibrary/40.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">
            Interior Design
          </p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px] ml-10 mt-10">
          <img
            src={require("../../assets/careerlibrary/41.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">
            Accessory Design
          </p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px] mt-10 ">
          <img
            src={require("../../assets/careerlibrary/42.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">
            Jewellery Design
          </p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px] ml-10 mt-10">
          <img
            src={require("../../assets/careerlibrary/43.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">
            Leather Design
          </p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px] mt-10 ">
          <img
            src={require("../../assets/careerlibrary/44.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">
            Fashion Business Management
          </p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px] ml-10 mt-10">
          <img
            src={require("../../assets/careerlibrary/45.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">
            Product Design
          </p>
        </div>
      </div>
    </div>
  );
};

export default Desgin;
