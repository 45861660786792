import React from "react";

const Computerapplication = () => {
  return (
    <div className=" mt-[100px]">
      <div className="w-full grid-cols-1 flex items-center justify-center">
        <div className="w-full h-[300px] bg-[#64964d]">
          <h2 className="text-white text-3xl text-center mt-[120px]">
            Career Library
          </h2>
        </div>
      </div>
      <div className="mx-[100px] text-justify">
        <h3 className="text-2xl mt-10 ">Computer Application & IT</h3>
        <hr ></hr>
        <p className=" mt-6 text-md">
          In today's techno-savvy world, no industry survives without adopting
          the latest technology & hence graduates in the field of computer
          applications are always in demand. Bachelors in the computer
          application is a mainstream career option and a very sought-after
          course amongst students. A degree in computer application creates
          substantial scope for an excellent career.
          <br></br>
          <p className="mt-4">
            Entry to premier colleges requires students to pass an entrance
            exam. Considering the fast pace growth of this sector, lots of
            students are pursuing this career path thus making it increasingly
            competitive. Graduates in this field need to constantly upgrade
            their skill set to keep up with the rapidly changing technology. For
            a career in Computer Application one can do BCA, a three year
            bachelor program and follow it up with MCA, a masters program. While
            its imperative to have Mathematics at 10+2 level, it's also
            recommended that the candidate chooses a combination of Science
            (Physics, Chemistry, Maths) with Computer Science as it increases
            the odds of grasping concepts faster.
          </p>
          <br></br>
          <p className="mt-4">
            While demand for Computer Application Graduates is becoming sector
            agnostic, companies like Accenture, TCS, TechMahindra, IGATE and
            StartUps like Flipkart & UBER continue to be the top recruiters.
          </p>
        </p>
      </div>
      <p className="mt-6 text-md mx-[100px]">
        Trending fields: Web/ Mobile Design Engineer, Web/ Mobile App Developer,
        UI/UX Designers, Technical Writers.
      </p>
      <div className="flex flex-wrap mt-8 mx-[100px] mb-8">
        <div className="items-left w-[400px] h-[200px] mb-[40px] ">
          <img
            src={require("../../assets/careerlibrary/32.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">
            Computer Application and IT
          </p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px] ml-10 ">
          <img
            src={require("../../assets/careerlibrary/33.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">
            Game Development
          </p>
        </div>
      </div>
    </div>
  );
};

export default Computerapplication;
