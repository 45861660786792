import React from "react";

const Economic = () => {
  return (
    <div className=" mt-[100px]">
      <div className="w-full grid-cols-1 flex items-center justify-center">
        <div className="w-full h-[300px] bg-[#64964d]">
          <h2 className="text-white text-3xl text-center mt-[120px]">
            Career Library
          </h2>
        </div>
      </div>
      <div className="mx-[100px] text-justify">
        <h3 className="text-2xl mt-10 ">Economics</h3>
        <hr ></hr>
        <p className=" mt-6 text-md">
          Economics is the study of deep insight in economical functions at
          macro and micro levels to handle the global market. It is the science
          of economic system and theories that manages production, distribution
          and consumption of goods and services. Economics favours quantitative
          skills and critical thinkers.
          <br></br>
          <p className="mt-4">
            Fields involved in economics are econometrics, international
            economics, economic policy making, public finance, development
            economics, monitory economics, etc.
          </p>
          <br></br>
          <p className="mt-4">
            Economics as a career provides good opportunities to conquer an edge
            in today's globalizing world. The industrial sector has great demand
            for economist at both the national and international level. The
            fastest growth in demand for economics is being witnessed in the
            private sector, especially in the areas of consulting and economic
            research. Economists are equally important in the government sector,
            entry to which is on the basis of UPSC examination (Civil Services
            in IES).
          </p>
          <br></br>
          <p className="mt-4">
            Candidates aspiring for career in Economics must have passed the
            10+2 (HSC) examination from recognized Board/University with
            Economics as one of the recommended subjects. One can move on to
            pursue Bachelor's degree in Economics followed by a Master's Degree
            or Doctor of Philosophy degree for prospective economists to be
            competitive. However, job prospects are open after graduation
            itself.
          </p>
          <br></br>
          <p className="mt-4">
            The top recruiters include Private and Public Banks, Private and
            Government Research Centres, Multi National Companies, Consulting
            Companies.
          </p>
        </p>
      </div>
      <p className="mt-6 text-md mx-[100px]">
        Trending Field: Business Economics, Monetary economics, Financial
        economics
      </p>
      <div className="flex flex-wrap mt-8 mx-[100px] mb-8">
        <div className="items-left w-[400px] h-[200px] mb-[40px] ">
          <img
            src={require("../../assets/careerlibrary/46.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">Economics</p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px] ml-10 ">
          <img
            src={require("../../assets/careerlibrary/47.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">
            Indian Economic Service
          </p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px] ml-10 ">
          <img
            src={require("../../assets/careerlibrary/48.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">
            International Relations
          </p>
        </div>
      </div>
    </div>
  );
};

export default Economic;
