import React from "react";

const Civilservice = () => {
  return (
    <div className=" mt-[100px]">
      <div className="w-full grid-cols-1 flex items-center justify-center">
        <div className="w-full h-[300px] bg-[#64964d]">
          <h2 className="text-white text-3xl text-center mt-[120px]">
            Career Library
          </h2>
        </div>
      </div>
      <div className="mx-[100px] text-justify">
        <h3 className="text-2xl mt-10 ">Civil Services</h3>
        <hr ></hr>
        <p className=" mt-6 text-md">
          The civil service system is the backbone of the administrative
          machinery of the country. The Union Public Service Commission conducts
          a nationwide competitive examination for recruitment to various Civil
          Services of the Government of India. Civil Services offer an
          attractive and challenging career to the ambitious, aspiring and the
          talented youth of the country. The wide variety of jobs within the
          fold of the Civil Services have relatively greater sphere of authority
          and power than any other services in India.
          <br></br>
          <p className="mt-4">
            It is considered to be one of the toughest examination in India with
            success rate of 0.1%-0.3% with more than 900,000 applicants. The
            challenging aspect of this exam is that aspirants must complete a
            three-stage process, requiring in depth knowledge, rational
            thinking, drafting skills, creativity, language competency, a can do
            and empathetic personality.
          </p>
          <br></br>
          <p className="mt-4">
            The candidate applying for civil services examination must hold a
            degree of any central, state or deemed university. There are certain
            restrictions on age limit and number of attempts.
          </p>
          <br></br>
          <p className="mt-4">
            Jobs in civil services are symbols of great power, social
            recognition and affluence. A wide variety of work domains are
            associated with civil services, like maintenance of law and order,
            developmental work, disaster management, representing India on
            international forums, administration, and empowerment of
            marginalized sections of society etc.
          </p>
        </p>
      </div>
      <p className="mt-6 text-md mx-[100px]">
        Trending fields: Indian Administrative Services, Indian Foreign Service,
        Indian Police Service.
      </p>
      <div className="flex flex-wrap mt-8 mx-[100px] mb-8">
        <div className="items-left w-[400px] h-[200px] mb-[40px]  ">
          <img
            src={require("../../assets/careerlibrary/17.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">Railways</p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px] ml-10">
          <img
            src={require("../../assets/careerlibrary/18.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">Customs</p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px] ml-10 ">
          <img
            src={require("../../assets/careerlibrary/19.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">Excise</p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px] mt-10 ">
          <img
            src={require("../../assets/careerlibrary/20.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">Income Tax</p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px] ml-10 mt-10">
          <img
            src={require("../../assets/careerlibrary/21.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">
            Civil Service
          </p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px]  ml-10 mt-10">
          <img
            src={require("../../assets/careerlibrary/22.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">
            Indian Statistical Service
          </p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px]  mt-10">
          <img
            src={require("../../assets/careerlibrary/23.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">
            Indian Foreign Service{" "}
          </p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px] ml-10 mt-10">
          <img
            src={require("../../assets/careerlibrary/24.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">
            Indian Legal Service
          </p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px] ml-10 mt-10">
          <img
            src={require("../../assets/careerlibrary/25.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">
            Central Labour Service
          </p>
        </div>
      </div>
    </div>
  );
};

export default Civilservice;
