import React from "react";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import Actuarialscience from "../Components/Actuarialscience/Actuarialscience";


export const ActuarialScience = () => {
  return (
    <div className="overflow-hidden">
      <Header />
      <Actuarialscience/>
      <Footer />
    </div>
  );
};
