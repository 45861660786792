import React from "react";
import "./banner.css";

const BannerInstitute = () => {
  return (
    <div className="bannerinstitute text-white xl:h-[34em] lg:h-[34em] md:h-[34em] 2xl:h-[38em] h-[34em] flex items-center">
      <div className="max-w-[1100px] mx-auto flex flex-col justify-center items-center mt-[10%] ">
        <div className="text-center md:text-left">
          <p className="page_font text-white font-bold p-2 md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-5xl text-xl text-center">
            Career Guidance for the Institute's
          </p>
          <p className="page_font text-white font-bold p-2 md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-5xl text-xl text-center">
            Bright Minds to perform well
          </p>
        </div>
        <div className="flex items-center justify-center flex-col md:flex-row w-full mt-4">
          <a href="/contacts">
            <button className="lg:w-[170px] lg:h-[60px] w-[150px] h-[50px] border border-2xl rounded-xl bg-black text-white lg:text-2xl text-1xl mt-3">
              Contact Us
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default BannerInstitute;
