import React from "react";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import Appliedart from "../Components/Appliesart/Appliedart";


export const AppliedArt = () => {
  return (
    <div className="overflow-hidden">
      <Header />
      <Appliedart/>
      <Footer />
    </div>
  );
};
