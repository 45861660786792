import React from "react";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import Desgin from "../Components/Desgin/Desgin";


export const Designpage = () => {
  return (
    <div className="overflow-hidden">
      <Header />
    <Desgin/>
      <Footer />
    </div>
  );
};
