import React from "react";

const Works = () => {
  return (
    <div className="mx-auto">
      <div className="text-3xl font-bold text-black text-center mt-[90px]">
        <h2>How It Works</h2>
      </div>
      <div className="max-w-[1200px] md:mt-[10px] md:mb-[15px] mx-auto md:grid grid-cols-2 gap-10 px-8 py-4">
        <div className="drop-shadow-2xl lg:shadow-2xl lg:shadow-slate-950 rounded-[20px] block h-[400px] my-[5vw]  ">
          <img
            src={require("../../assets/background/432.jpg")}
            alt=""
            className="w-full rounded-[20px] h-full object-cover "
          />
        </div>
        <div className=" flex flex-col justify-center">
          <h3 className="subheading_main   text-center  p-2 md:text-3xl text-3xl flex justify-center max-md:mt-[10px] md:mb-[1vw] mb-[3vw] sm:tracking-wide tracking-[-1px] px-10">
            Advance Assessment & Best-Fit Matches
          </h3>
          <p className="text-justify px-10">
            Advance Assessment involves the strategic evaluation of an
            individual's skills, aptitudes, and characteristics to make informed
            decisions about educational and career paths.On the other hand,
            Best-Fit Matches focus on aligning individuals with opportunities
            that complement their strengths, reducing mismatches, and
            contributing to long-term success and satisfaction.
          </p>
          <button className="border border-2px rounded-xl bg-black w-[150px] h-[50px] mx-auto text-white mt-4 hover:scale-110 duration-100 hover:text-gray-400 px-6">
            Read More
          </button>
        </div>
      </div>
      <div className="max-w-[1200px] mx-auto md:grid grid-cols-2 gap-10 px-8 py-4 ">
        <div className=" md:hidden block  h-[400px] my-10 drop-shadow-2xl rounded-[20px] lg:shadow-2xl lg:shadow-slate-950 ">
          <img
            src={require("../../assets/background/765.jpg")}
            alt=""
            className="w-full h-full object-cover rounded-[20px]"
          />
        </div>
        <div className=" flex flex-col justify-center">
          <h3 className="subheading_main   text-center p-2 md:text-3xl text-3xl flex justify-center max-md:mt-[10px] md:mb-[1vw] mb-[3vw] sm:tracking-wide tracking-[-2.5px] px-10">
            Report As per Exam & Best suited options for you
          </h3>
          <p className="text-justify px-10">
            The "Report As per Exam & Best Suited Options for You" is a
            personalized and data-driven approach to assessing an individual's
            performance in exams and providing tailored recommendations for
            future endeavors. This process involves analyzing exam results,
            considering individual strengths and weaknesses, and generating
            insights to guide individuals toward the most suitable academic or
            career paths.
          </p>
          <button className="border border-2px rounded-xl bg-black w-[150px] h-[50px] mx-auto text-white mt-4 hover:scale-110 duration-100 hover:text-gray-400 px-6">
            Read More
          </button>
        </div>
        <div className="md:block hidden h-[400px] my-10 drop-shadow-2xl rounded-[20px] lg:shadow-2xl lg:shadow-slate-950">
          <img
            src={require("../../assets/background/765.jpg")}
            alt=""
            className="w-full h-full object-cover rounded-[20px]"
          />
        </div>
      </div>
      <div className="max-w-[1200px] md:mt-[30px] md:mb-[15px] mx-auto md:grid grid-cols-2 gap-10 px-8 py-4">
        <div className="drop-shadow-2xl lg:shadow-2xl lg:shadow-slate-950 rounded-[20px] block h-[400px] my-[5vw]">
          <img
            src={require("../../assets/background/765.png")}
            alt=""
            className="w-full rounded-[20px] h-full object-cover "
          />
        </div>
        <div className=" flex flex-col justify-center">
          <h3 className="subheading_main   text-center  p-2 md:text-3xl text-3xl flex justify-center max-md:mt-[10px] md:mb-[1vw] mb-[3vw] sm:tracking-wide tracking-[-1px] px-10">
            Schedule Meetings with our experts to know more about your Career
          </h3>
          <p className="text-justify px-10">
            "Scheduling Meetings with Our Experts to Know More About Your
            Career" offers individuals an invaluable opportunity to gain
            personalized insights and guidance regarding their career
            trajectory. By engaging with seasoned professionals and experts,
            individuals can receive tailored advice, explore potential career
            paths, and address specific queries related to their professional
            journey. These meetings provide a platform for one-on-one
            interactions, allowing individuals to discuss their aspirations,
            skills, and challenges.
          </p>
          <button className="border border-2px rounded-xl bg-black w-[150px] h-[50px] mx-auto text-white mt-4 hover:scale-110 duration-100 hover:text-gray-400 px-6">
            Read More
          </button>
        </div>
      </div>
      <div className="max-w-[1200px] mx-auto md:grid grid-cols-2 gap-10 px-8 py-4 ">
        <div className=" md:hidden block  h-[400px] my-10 drop-shadow-2xl rounded-[20px] lg:shadow-2xl lg:shadow-slate-950  ">
          <img
            src={require("../../assets/background/67.webp")}
            alt=""
            className="w-full h-full object-cover rounded-[20px]"
          />
        </div>
        <div className=" flex flex-col justify-center">
          <h3 className="subheading_main   text-center p-2 md:text-3xl text-3xl flex justify-center max-md:mt-[10px] md:mb-[1vw] mb-[3vw] sm:tracking-wide tracking-[-2.5px] px-10 ">
            Personalised Guidance grom Expert
          </h3>
          <p className="text-justify px-10">
            It is a valuable resource for individuals seeking tailored advice and
            insights to navigate their personal and professional journeys. This
            personalized approach involves connecting with seasoned experts who
            offer targeted guidance based on an individual's unique goals,
            skills, and challenges. Whether exploring career paths, educational
            opportunities, or personal development strategies, receiving advice
            from experts ensures a customized and effective roadmap.This
            personalized guidance empowers individuals to make informed
            decisions, overcome obstacles, and achieve their aspirations with
            confidence.
          </p>
          <button className="border border-2px rounded-xl bg-black w-[150px] h-[50px] mx-auto text-white mt-4 hover:scale-110 duration-100 hover:text-gray-400">
            Read More
          </button>
        </div>
        <div className="md:block hidden h-[400px] my-10 drop-shadow-2xl rounded-[20px] lg:shadow-2xl lg:shadow-slate-950 ">
          <img
            src={require("../../assets/background/67.webp")}
            alt=""
            className="w-full h-full object-cover rounded-[20px]"
          />
        </div>
      </div>
      <div className="max-w-[1200px] md:mt-[30px] md:mb-[15px] mx-auto md:grid grid-cols-2 gap-10 px-8 py-4">
        <div className="drop-shadow-2xl lg:shadow-2xl lg:shadow-slate-950 rounded-[20px] block h-[400px] my-[5vw] ">
          <img
            src={require("../../assets/background/work6.png")}
            alt=""
            className="w-full rounded-[20px] h-full object-cover "
          />
        </div>
        <div className=" flex flex-col justify-center">
          <h3 className="subheading_main   text-center  p-2 md:text-3xl text-3xl flex justify-center max-md:mt-[10px] md:mb-[1vw] mb-[3vw] sm:tracking-wide tracking-[-1px] px-10">
            Career Roadmap Planner
          </h3>
          <p className="text-justify px-10">
            A "Career Roadmap Planner" serves as a strategic tool for
            individuals to chart their professional journey with clarity and
            purpose. This resource helps individuals outline key milestones, set
            achievable goals, and identify the necessary steps to reach their
            desired career destinations. By providing a structured plan, the
            Career Roadmap Planner assists in aligning skills development,
            educational pursuits, and job experiences in a coherent manner. The
            Career Roadmap Planner is an invaluable asset for those seeking a
            methodical and well-defined strategy to guide them toward long-term
            success in their careers.
          </p>
          <button className="border border-2px rounded-xl bg-black w-[150px] h-[50px] mx-auto text-white mt-4 hover:scale-110 duration-100 hover:text-gray-400 px-6">
            Read More
          </button>
        </div>
      </div>
    </div>
  );
};

export default Works;
