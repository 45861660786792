import React from "react";

const Cabincrew= () => {
  return (
    <div className=" mt-[100px]">
      <div className="w-full grid-cols-1 flex items-center justify-center">
        <div className="w-full h-[300px] bg-[#64964d]">
          <h2 className="text-white text-3xl text-center mt-[120px]">
            Career Library
          </h2>
        </div>
      </div>
      <div className="mx-[100px] text-justify">
        <h3 className="text-2xl mt-10 ">Cabin Crew</h3>
        <hr ></hr>
        <p className=" mt-6 text-md">
          Cabin Crew can be an excellent career choice if travelling across the
          world and interacting with new people excites you. Cabin crew are the
          in-flight members that make passengers comfortable while they are
          flying to their destinations. They look after the passengers boarding,
          seats, luggage, food and basic requirements along with their safety by
          introducing them to certain safety measures. They also need to provide
          a written report about all the records of the flight, which includes
          sales report about the food and other products, and an account of any
          unusual experiences.
          <br></br>
          <p className="mt-4">
            Training in cabin crew services include the study of the profession,
            passenger handling, in-flight procedures and emergencies, duties
            after landing, flight evaluation, dealing with passengers with
            special needs, food and beverage services among others.
          </p>
          <br></br>
          <p className="mt-4">
            To become a member of cabin crew one has to be physically fit and
            attractive. Some airlines have a specific requirement in terms of
            weight and height. Candidates should also possess good communication
            and interpersonal skills with the right amount of tactfulness.
            Selection is usually on the basis of an interview followed by a
            group discussion.
          </p>
          <br></br>
          <p className="mt-4">
            The candidates are eligible to apply after completing class 12th.
            Before starting work as a professional, one is required to gain some
            basic knowledge about the job through a course that lasts for around
            6 months to a year. A degree in hotel management is recommended for
            fast track movement and good growth potential.
          </p>
        </p>
      </div>
      <p className="mt-6 text-md mx-[100px]">
        Recruiters include all the leading International and Domestic Airlines
        such as Singapore Air, Emirates, Jet Airways, Air India, Indigo etc.
      </p>
      <div className="flex flex-wrap mt-8 mx-[100px] mb-8">
        <div className="items-left w-[400px] h-[200px] mb-[40px] ">
          <img
            src={require("../../assets/careerlibrary/16.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">Cabin Crew</p>
        </div>
      </div>
    </div>
  );
};

export default Cabincrew;
