import React from "react";

const Appliedart = () => {
  return (
    <div className=" mt-[100px]">
      <div className="w-full grid-cols-1 flex items-center justify-center">
        <div className="w-full h-[300px] bg-[#64964d]">
          <h2 className="text-white text-3xl text-center mt-[120px]">
            Career Library
          </h2>
        </div>
      </div>
      <div className="mx-[100px] text-justify">
        <h3 className="text-2xl mt-10 ">Applied Arts</h3>
        <hr ></hr>
        <p className=" mt-6 text-md">
          Applied Arts is the art of producing objects, which are aesthetic,
          utilitarian and also easy to use for people. Hence, these artists
          apply creative arts to practical use. It is about combining artistic
          vision with commercial need. Work of an applied artist can be seen in
          most basic objects such as a table, chair, utensils and also in
          complex objects like smartphones, microwave, food processor amongst
          many others. Applied arts is often confused with fine arts. Fine Arts
          forms the basis for applied arts but it requires much more than that.
          <br></br>
          <p className="mt-4">
            {" "}
            Various fields in applied arts include graphic arts, architecture,
            industrial designing, decorative arts, jewelry, photography etc.
          </p>
          <br></br>
          <p className="mt-4">
            {" "}
            Selection to some universities for a bachelor of fine arts and
            applied arts is made on the basis of an aptitude test consisting of
            drawing, creative composition in painting, sculpture in clay and
            graphic design.
          </p>
          <br></br>{" "}
          <p className="mt-4">
            There are many universities that provide bachelor's degree in
            applied arts or one can also pursue bachelors in fine arts with
            honors in applied arts.
          </p>
          <br></br>{" "}
          <p className="mt-4">
            Job prospects are good after graduation. Applied Arts professionals
            take up jobs in sectors like animation, advertising companies, dance
            studios, television, software companies, magazines, digital media
            etc.
          </p>
        </p>
      </div>
      <p className="mt-6 text-md mx-[100px]">
        Trending Field: Animation, Graphic Designer, Advertising etc.
      </p>
      <div className="flex flex-wrap mt-8 mx-[100px]">
        <div className="items-left w-[400px] h-[200px] mb-[40px] ">
          <img
            src={require("../../assets/careerlibrary/1.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">Fine Arts</p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px] ml-10">
          <img
            src={require("../../assets/careerlibrary/2.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">Film Making</p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px] ml-10">
          <img
            src={require("../../assets/careerlibrary/3.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">Photography</p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[80px]  mt-8">
          <img
            src={require("../../assets/careerlibrary/4.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px]  text-center">Exhibition Curator</p>
        </div>
      </div>
    </div>
  );
};

export default Appliedart;
