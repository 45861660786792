import React from "react";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import Contactus from "../Components/Contactus/Contactus";

export const ContactUs = () => {
  return (
    <div className="overflow-hidden">
      <Header />
      <Contactus/>
      <Footer />
    </div>
  );
};
