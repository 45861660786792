import React, { useState, useEffect } from "react";
import "./OurPartner.css";



const OurPartner = () => {
  const [activeComponent, setActiveComponent] = useState("PartnerSchools");

  useEffect(() => {
   
  }, []); 

  const handleButtonClick = (componentName) => {
    setActiveComponent(componentName);
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case "PartnerSchools":
        return <PartnerSchools />;
      case "PartnerUniversities":
        return <PartnerUniversities />;
      default:
        return null;
    }
  };

  return (
    <div className="overflow-hidden section-padding bg-cover bg-no-repeat bg-center">
      <div className="container mx-auto">
        <div className="lg:text-3xl text- font-bold text-black text-center mt-10">
          <h2>Our Partner Institutions</h2>
        </div>

        <div className="relative flex justify-center gap-4 mt-10">
          <button
            className="OurPartner"
            onClick={() => handleButtonClick("PartnerSchools")}
          >
            50+ Partner Schools
          </button>
          <button
            className="OurPartner"
            onClick={() => handleButtonClick("PartnerUniversities")}
          >
            25+ Partner Universities
          </button>
        </div>

        {renderComponent()}

        <p className="my-6 text-justify"></p>
      </div>
    </div>
  );
};

const PartnerSchools = () => (
  <div>
    <div className="max-w-[1240px] mx-auto grid grid-cols-4 gap-6 mt-10 px-10 ">
      <div className="col-span-1 shadow-xl h-[170px]  my-4 border border-2px gap-6 hover:scale-110 duration-100 ">
        <div className="text-center font-medium items-center ">
          <img
            className="w-[50%] mt-3 ml-[80px]"
            src={require("../../assets/institutes/Welham_Girls'_School_logo.avif")}
            alt=""
          />
          <h4 className="mt-3">Welham Girls' School, Dehradun</h4>
        </div>
      </div>
      <div className="col-span-1 shadow-xl h-[170px] my-4  border border-2px gap-6 hover:scale-110 duration-100">
        <div className="text-center font-medium">
          <img
            className="w-[30%] mt-3 ml-[100px]"
            src={require("../../assets/institutes/The hyderabad public school.avif")}
            alt=""
          />
          <h4 className="">Hyderabad Public School, Begumpet, Hyderabad</h4>
        </div>
      </div>
      <div className="col-span-1 shadow-xl h-[170px]  my-4 border border-2px gap-6 hover:scale-110 duration-100">
        <div className="text-center font-medium">
          <img
            className="w-[65%] mt-3 ml-[80px]"
            src={require("../../assets/institutes/MayoGirlsCollege_new.png")}
            alt=""
          />
          <h4 className="mt-2">Mayo Girls College, Ajmer</h4>
        </div>
      </div>
      <div className="col-span-1 shadow-xl h-[170px]  my-4 border border-2px gap-6 hover:scale-110 duration-100">
        <div className="text-center font-medium">
          <img
            className="w-[30%] mt-2 ml-[100px]"
            src={require("../../assets/institutes/Punjab public school.avif")}
            alt=""
          />
          <h4 className="mt-2">Punjab Public School, Nabha</h4>
        </div>
      </div>
      <div className="col-span-1 shadow-xl h-[170px] my-4 border border-2px hover:scale-110 duration-100">
        <div className="text-center font-medium">
          <img
            className="w-[65%] mt-2 ml-[60px]"
            src={require("../../assets/institutes/Gems_Academy.avif")}
            alt=""
          />
          <h4 className="mt-3">GEMS Modern Academy, Kochi</h4>
        </div>
      </div>
      <div className="col-span-1 shadow-xl h-[170px] my-4 border border-2px hover:scale-110 duration-100 ">
        <div className="text-center font-medium">
          <img
            className="w-[30%] ml-[100px]"
            src={require("../../assets/institutes/Greenwood High school_new.avif")}
            alt=""
          />
          <h4 className="">Greenwood High School, Bannerghatta, Bengaluru</h4>
        </div>
      </div>
      <div className="col-span-1 shadow-xl h-[170px] my-4 border border-2px hover:scale-110 duration-100">
        <div className="text-center font-medium">
          <img
            className="w-[30%] mt-1 ml-[100px]"
            src={require("../../assets/institutes/Scindia.avif")}
            alt=""
          />
          <h4 className="mt-1">Scindia Kanya Vidyalaya, Gwalior</h4>
        </div>
      </div>
      <div className="col-span-1 shadow-xl h-[170px] my-4 border border-2px hover:scale-110 duration-100">
        <div className="text-center font-medium">
          <img
            className="w-[22%]  mt-2 ml-[100px]"
            src={require("../../assets/institutes/St. George's college_new.avif")}
            alt=""
          />
          <h4 className="mt-2">St George's College, Mussoorie</h4>
        </div>
      </div>
      <div className="col-span-1 shadow-xl h-[170px] my-4 border border-2px hover:scale-110 duration-100">
        <div className="text-center font-medium">
          <img
            className="w-[26%] mt-1 ml-[100px]"
            src={require("../../assets/institutes/The_Doon_School,_Dehradun_-_logo_new.avif")}
            alt=""
          />
          <h4 className="mt-2">The Doon School, Dehradun</h4>
        </div>
      </div>
      <div className="col-span-1 shadow-xl h-[170px] my-4 border border-2px hover:scale-110 duration-100">
        <div className="text-center font-medium">
          <img
            className="w-[25%]  mt-2 ml-[100px]"
            src={require("../../assets/institutes/oxford_international.avif")}
            alt=""
          />
          <h4 className="mt-1">
            Oxford International School, Hoshiarpur, Punjab
          </h4>
        </div>
      </div>
      <div className="col-span-1 shadow-xl h-[170px] my-4 border border-2px hover:scale-110 duration-100 ">
        <div className="text-center font-medium">
          <img
            className="w-[26%]  mt-2  ml-[100px]"
            src={require("../../assets/institutes/GIIS Dubai.avif")}
            alt=""
          />
          <h4 className="">GEMS Our Own English High School Boys, Dubai</h4>
        </div>
      </div>
      <div className="col-span-1 shadow-xl h-[170px] my-4 border border-2px hover:scale-110 duration-100">
        <div className="text-center font-medium">
          <img
            className="w-[25%] mt-2 ml-[100px]"
            src={require("../../assets/institutes/Welham boys school.avif")}
            alt=""
          />
          <h4 className="mt-2">Welham Boys' School, Dehradun</h4>
        </div>
      </div>
    </div>
  </div>
);

const PartnerUniversities = () => (
  <div className="max-w-[1240px] mx-auto grid grid-cols-4 gap-6 mt-10 px-10">
    <div className="col-span-1 shadow-xl h-[170px]  my-4 border border-2px gap-6 hover:scale-110 duration-100 ">
      <div className="text-center font-medium">
        <img
          className="w-[94%] mt-3 ml-[10px]"
          src={require("../../assets/institutes/Acharya_Logo1.avif")}
          alt=""
        />
        <h4 className="mt-6">Acharya Institues, Bengaluru</h4>
      </div>
    </div>
    <div className="col-span-1 shadow-xl h-[170px] my-4  border border-2px gap-6 hover:scale-110 duration-100">
      <div className="text-center font-medium">
        <img
          className="w-[70%] ml-[50px]"
          src={require("../../assets/institutes/Ahmedabad University .avif")}
          alt=""
        />
        <h4 className="">Ahmedabad University, Ahmedabad</h4>
      </div>
    </div>
    <div className="col-span-1 shadow-xl h-[170px]  my-4 border border-2px gap-6 hover:scale-110 duration-100">
      <div className="text-center font-medium">
        <img
          className="w-[65%] ml-[50px]"
          src={require("../../assets/institutes/Alliance University_new.avif")}
          alt=""
        />
        <h4 className="">Alliance University, Bengaluru</h4>
      </div>
    </div>
    <div className="col-span-1 shadow-xl h-[170px]  my-4 border border-2px gap-6 hover:scale-110 duration-100">
      <div className="text-center font-medium">
        <img
          className="w-[60%]  ml-[60px]"
          src={require("../../assets/institutes/Amity University.avif")}
          alt=""
        />
        <h4 className="">Amity Univesity, Multiple Campus</h4>
      </div>
    </div>
    <div className="col-span-1 shadow-xl h-[170px] my-4 border border-2px hover:scale-110 duration-100">
      <div className="text-center font-medium">
        <img
          className="w-[65%] mt-2 ml-[60px]"
          src={require("../../assets/institutes/Atria University.avif")}
          alt=""
        />
        <h4 className="mt-1">Atria University, Bangalore</h4>
      </div>
    </div>
    <div className="col-span-1 shadow-xl h-[170px] my-4 border border-2px hover:scale-110 duration-100">
      <div className="text-center font-medium">
        <img
          className="w-[50%] ml-[70px]"
          src={require("../../assets/institutes/AURO-Surat-Gujarat_LOGO_Aug2023.webp")}
          alt=""
        />
        <h4 className="">Auro University</h4>
      </div>
    </div>
    <div className="col-span-1 shadow-xl h-[170px] my-4 border border-2px hover:scale-110 duration-100 ">
      <div className="text-center font-medium">
        <img
          className="w-[70%] mt-1 ml-[40px]"
          src={require("../../assets/institutes/BMLMunjal_new.avif")}
          alt=""
        />
        <h4 className="mt-2">BML Munjal University, Gurgaon</h4>
      </div>
    </div>
    <div className="col-span-1 shadow-xl h-[170px] my-4 border border-2px hover:scale-110 duration-100">
      <div className="text-center font-medium">
        <img
          className="w-[25%]  mt-2 ml-[100px]"
          src={require("../../assets/institutes/FLAME logo_new.avif")}
          alt=""
        />
        <h4 className="mt-2">FLAME University, Pune</h4>
      </div>
    </div>
    <div className="col-span-1 shadow-xl h-[170px] my-4 border border-2px hover:scale-110 duration-100">
      <div className="text-center font-medium">
        <img
          className="w-[70%] mt-1 ml-[40px]"
          src={require("../../assets/institutes/GD Goenka_new.avif")}
          alt=""
        />
        <h4 className="mt-2">GD Goenka University, Sohna Road, Gurugram</h4>
      </div>
    </div>
    <div className="col-span-1 shadow-xl h-[170px] my-4 border border-2px hover:scale-110 duration-100">
      <div className="text-center font-medium">
        <img
          className="w-[40%]  mt-2 ml-[80px]"
          src={require("../../assets/institutes/ICSI_Logo.avif")}
          alt=""
        />
        <h4 className="mt-1">
          Institute of Company Secretaries of India (ICSI), Delhi
        </h4>
      </div>
    </div>
    <div className="col-span-1 shadow-xl h-[170px] my-4 border border-2px hover:scale-110 duration-100">
      <div className="text-center font-medium">
        <img
          className="w-[30%]  mt-2 ml-[90px]"
          src={require("../../assets/institutes/IIHM.avif")}
          alt=""
        />
        <h4 className="">
          IIHM (International Institute Of Hotel Management), Badarpur, Delhi
        </h4>
      </div>
    </div>
    <div className="col-span-1 shadow-xl h-[170px] my-4 border border-2px hover:scale-110 duration-100">
      <div className="text-center font-medium">
        <img
          className="w-[40%] mt-2 ml-[80px]"
          src={require("../../assets/institutes/Kit.webp")}
          alt=""
        />
        <h4 className="mt-2">KIIT University</h4>
      </div>
    </div>
  </div>
);

export default OurPartner;

