import React from "react";

const Student = () => {
  return (
    <div className=" mx-auto  teacher-student">
      <h3 className="flex justify-center  mt-[100px] lg:text-3xl text-md text-black font-bold">
        Welcome to Expert Career Guidance
      </h3>
      <br></br>
      <h2 className=" flex justify-center lg:text-3xl  text-md font-bold">
        Personalised & Comprehensive Career Solutions
      </h2>
      <div className="max-w-[1240px] mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 px-10 py-6">
        <div className="col-span-1 shadow-xl h-[550px] my-4 border border-2px transform hover:scale-110 duration-100 rounded-2xl">
          <h2 className="text-2xl font-bold text-center py-8">
            Class 9th & 10th
          </h2>
          <div className="text-center font-medium">
            <img
              className="relative object-cover  mx-auto "
              src={require("../../assets/career/1.jpeg")}
              alt=""
            />
            <p className="py-2 px-6 mt-8 ">
              Guidance for right<br></br>
              <span className="text-black">
                Combination and subjects(stream selection)
              </span>
            </p>
            <a href="class-9th-and-10th-counselling">
              <button className="bg-[black] w-[180px] flex justify-center rounded-md font-medium my-6 mx-auto px-4 py-3 text-[white]">
                Explore
              </button>
            </a>
          </div>
        </div>
        <div className="col-span-1 shadow-xl h-[550px] my-4 border border-2px transform hover:scale-110 duration-100 rounded-2xl">
          <h2 className="text-2xl font-bold text-center py-8">
            Class 11th & 12th
          </h2>
          <div className="text-center font-medium">
            <img
              className="relative object-cover  mx-auto "
              src={require("../../assets/career/24.jpeg")}
              alt=""
            />
            <p className="py-2 px-6 mt-8">
              Guidance for best<br></br>
              <span className="text-black">
                Course & Selecting right institutes
              </span>
            </p>
            <a href="class-11th-and-12th-counselling">
              <button className="bg-[black] w-[180px] flex justify-center rounded-md font-medium my-6 mx-auto px-4 py-3 text-[white] ">
                Explore
              </button>
            </a>
          </div>
        </div>
        <div className="col-span-1 shadow-xl h-[550px] my-4 border border-2px transform hover:scale-110 duration-100 rounded-2xl">
          <h2 className="text-2xl font-bold text-center py-8">
            College Students
          </h2>
          <div className="text-center font-medium">
            <img
              className="relative object-cover  mx-auto  "
              src={require("../../assets/career/45.jpeg")}
              alt=""
            />
            <p className="py-2 px-6 mt-8">
              Guidance for right<br></br>
              <span className="text-black">Profession</span>
            </p>
            <a href="college-counselling">
              <button className="bg-[black] w-[180px]  flex justify-center rounded-md font-medium my-6 mx-auto px-4 py-3 text-[white]">
                Explore
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Student;
