import React from "react";

const Testimonials = () => {
  return (
    <div className="mx-auto max-w-[1280px]">
      <div className="lg:text-3xl text-2xl font-bold text-black text-center mt-6">
        <h2>Testimonials</h2>
      </div>
      <div className=" mx-auto flex flex-wrap justify-center gap-6 mt-10 px-10">
        <div className="shadow-xl h-[500px] w-[270px]  my-4 border border-2px border-[#dad7d7] rounded-2xl flex flex-col items-center justify-center hover:scale-110 duration-100">
          <img
            src={require("../../assets/career/testi-6.jpg")}
            alt=""
            className="w-24 h-30 object-cover rounded-full py-2 px-2"
          />
          <div className="flex items-center mt-2">
            {Array(5)
              .fill()
              .map((_, index) => (
                <svg
                  key={index}
                  className="w-5 h-5 fill-current text-yellow-600 font-bold"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M12 2L14.45 8.29L21 9l-5 4.56L17 21l-5-4.73L7 21l1.55-6.71L2 9l6.55-.71L12 2zm0 2.32L10.97 9H5.06l4.11 3.16L8.54 18l3.46-2.84L16.94 18l-1.63-5.84L22 9h-5.91L12 4.32z" />
                </svg>
              ))}
          </div>
          <div className="text-center mt-8 mr-4 ml-4">
            <p className="text-sm text-black ">
              "Guide4Careers has been an absolute game-changer for me. The
              assessments provided deep insights into my strengths and
              interests, helping me discover the perfect career path. The
              personalized guidance from experts was invaluable. I highly
              recommend it!"
            </p>
            <p className="text-sm text-black text-center py-6">
              Sneha Verma <br></br> Collage Student
            </p>
          </div>
        </div>
        <div className="shadow-xl h-[500px] w-[270px]  my-4 border border-2px border-[#dad7d7] rounded-2xl  flex flex-col justify-center items-center hover:scale-110 duration-100 ">
          <img
            src={require("../../assets/career/testi-5.jpg")}
            alt=""
            className="w-24 h-30 object-cover rounded-full py-2 px-2"
          />
          <div className="flex items-center mt-2">
            {Array(5)
              .fill()
              .map((_, index) => (
                <svg
                  key={index}
                  className="w-5 h-5 fill-current text-yellow-600 font-bold"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M12 2L14.45 8.29L21 9l-5 4.56L17 21l-5-4.73L7 21l1.55-6.71L2 9l6.55-.71L12 2zm0 2.32L10.97 9H5.06l4.11 3.16L8.54 18l3.46-2.84L16.94 18l-1.63-5.84L22 9h-5.91L12 4.32z" />
                </svg>
              ))}
          </div>
          <div className="text-center mt-8 mr-4 ml-4">
            <p className="text-sm text-black">
              "As a parent, I couldn't be more satisfied with Guide4Careers. It
              provided a structured framework for my child's ambitions, and the
              mentorship program ensured they were on the right track. The
              personalized reports and career recommendations were spot-on."
            </p>
            <p className="text-sm text-black text-center py-6">
              Kavita<br></br> Parent
            </p>
          </div>
        </div>
        <div className="shadow-xl h-[500px] w-[270px]  my-4 border border-2px border-[#dad7d7] rounded-2xl  flex flex-col justify-center items-center hover:scale-110 duration-100 ">
          <img
            src={require("../../assets/career/testi-7.jpg")}
            alt=""
            className="w-24 h-30 object-cover rounded-full py-2 px-2 "
          />
          <div className="flex items-center mt-2">
            {Array(5)
              .fill()
              .map((_, index) => (
                <svg
                  key={index}
                  className="w-5 h-5 fill-current text-yellow-600 font-bold"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M12 2L14.45 8.29L21 9l-5 4.56L17 21l-5-4.73L7 21l1.55-6.71L2 9l6.55-.71L12 2zm0 2.32L10.97 9H5.06l4.11 3.16L8.54 18l3.46-2.84L16.94 18l-1.63-5.84L22 9h-5.91L12 4.32z" />
                </svg>
              ))}
          </div>
          <div className="text-center mt-8 mr-4 ml-4">
            <p className="text-sm text-black">
              "Guide4Careers has been an excellent resource for our institute.
              The career guidance tools have helped our students make informed
              decisions. The website is user-friendly, and the expert-led
              sessions have been well-received by our students."
            </p>
            <p className="text-sm text-black text-center py-8">
              Shaurya<br></br> Teacher
            </p>
          </div>
        </div>
        <div className="shadow-xl h-[500px] w-[270px]  my-4 border border-2px border-[#dad7d7] rounded-2xl  flex flex-col justify-center items-center mb-10 hover:scale-110 duration-100 ">
          <img
            src={require("../../assets/career/testi10.webp")}
            alt=""
            className="w-24 h-24 object-cover rounded-full py-2 px-2 "
          />
          <div className="flex items-center mt-2">
            {Array(5)
              .fill()
              .map((_, index) => (
                <svg
                  key={index}
                  className="w-5 h-5 fill-current text-yellow-600 font-bold"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M12 2L14.45 8.29L21 9l-5 4.56L17 21l-5-4.73L7 21l1.55-6.71L2 9l6.55-.71L12 2zm0 2.32L10.97 9H5.06l4.11 3.16L8.54 18l3.46-2.84L16.94 18l-1.63-5.84L22 9h-5.91L12 4.32z" />
                </svg>
              ))}
          </div>

          <div className="text-center mt-8 mr-4 ml-4">
            <p className="text-sm text-black ">
              "Switching careers can be overwhelming, but Guide4Careers made the
              transition seamless for me. The personalized guidance and best-fit
              matches opened up new opportunities. The schedule meetings with
              experts provided clarity and direction."
            </p>
            <p className="text-sm text-black text-center py-6">
              Ankita,<br></br> University Student
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
