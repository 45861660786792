import React from "react";

const Architecture = () => {
  return (
    <div className=" mt-[100px]">
      <div className="w-full grid-cols-1 flex items-center justify-center">
        <div className="w-full h-[300px] bg-[#64964d]">
          <h2 className="text-white text-3xl text-center mt-[120px]">
            Career Library
          </h2>
        </div>
      </div>
      <div className="mx-[100px] text-justify">
        <h3 className="text-2xl mt-10 ">Architecture</h3>
        <hr ></hr>
        <p className=" mt-6 text-md">
          Architecture is a highly ambitious field these days as structural
          designs and modern buildings are taking over not just the city, but
          the country's infrastructure is also getting a facelift and new
          avenues are opening up for the hopeful students. Architecture fields
          include Landscape Design, Urban Design and Planning, Ecological
          Management, Urban Conservation, Heritage Management amongst many
          others.
          <br></br>
          <p className="mt-4">
            While many people find this career fascinating and therefore lakhs
            of candidates apply and compete to get through the premier
            institutions through the entrance-test.
          </p>
          <br></br>
          <p className="mt-4">
            It is recommended that aspirants take Maths, Physics, Chemistry and
            Engineering Drawing at 10+2 level for this course. There are
            multiple entrance tests such as NATA which is National Aptitude Test
            of Architecture and on the basis of ranking /marks obtained in these
            entrance tests, the students are selected for college courses.
          </p>
          <br></br>
          <p className="mt-4">
            Top recruiting firms- Architecture Design Firms, Real Estate Firms,
            Engineering Firms, Government Bodies.
          </p>
        </p>
      </div>
      <p className="mt-6 text-md mx-[100px]">
        Trending fields - Landscape Designer, Interior Designer, Urban Design
        and Planning.
      </p>
      <div className="flex flex-wrap mt-8 mx-[100px] mb-8">
        <div className="items-left w-[400px] h-[200px] mb-[40px] ">
          <img
            src={require("../../assets/careerlibrary/10.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">
            Architecture
          </p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px] ml-10 ">
          <img
            src={require("../../assets/careerlibrary/11.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">
            Urban Planning
          </p>
        </div>
      </div>
    </div>
  );
};

export default Architecture;
