import React from "react";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import Defense from "../Components/Defense/Defense";


export const Defensepage = () => {
  return (
    <div className="overflow-hidden">
      <Header />
      <Defense/>
      <Footer />
    </div>
  );
};
