import React from "react";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import Architecture from "../Components/Architecture/Architecture";


export const Architecturepage = () => {
  return (
    <div className="overflow-hidden">
      <Header />
     <Architecture/>
      <Footer />
    </div>
  );
};
