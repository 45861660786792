import React from "react";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import Engineering from "../Components/Engineering/Engineering";


export const Engineeringpage = () => {
  return (
    <div className="overflow-hidden">
      <Header />
      <Engineering/>
      <Footer />
    </div>
  );
};
