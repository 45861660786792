import React from "react";

const Aviation = () => {
  return (
    <div className=" mt-[100px]">
      <div className="w-full grid-cols-1 flex items-center justify-center">
        <div className="w-full h-[300px] bg-[#64964d]">
          <h2 className="text-white text-3xl text-center mt-[120px]">
            Career Library
          </h2>
        </div>
      </div>
      <div className="mx-[100px] text-justify">
        <h3 className="text-2xl mt-10 ">Aviation</h3>
        <hr ></hr>
        <p className=" mt-6 text-md">
          Aviation is still amongst the most rewarding and fascinating careers.
          Aviation refers to all the activities involving the operation of
          aircrafts. The courses in India deal with Flying Operation, Aircrew,
          Aircraft, Air Traffic Management, Aviation safety and economics of the
          airport. Aircraft pilots are highly trained professionals and require
          intensive training & skill up gradation. Being a highly specialized
          job one is required to have knowledge of air navigation, interpreting
          meteorological reports, lead the aircraft under varied circumstances
          and be a leader to the crew and passengers.
          <br></br>
          <p className="mt-4">
            At technical level, a lot of skills are prerequisite for the pilots
            and they have to qualify many tests to get the license to fly.
            Getting the DGCA (Directorate General of Civil Aviation) license to
            fly the airplane is highly competitive in nature.
          </p>
          <br></br>
          <p className="mt-4">
            For a career as a Pilot, one should pass the 10+2 level with the
            subject combination of Physics, Chemistry and Maths. Post class 12th
            one can try and crack the entrance exams of the flying schools in
            India and also clear the physical fitness requirements.
          </p>
          <br></br>
          <p className="mt-4">
            Leading recruiters include well known International and domestic
            airlines such as Emirates, Singapore Air, Etihad, Jet Airways and
            Air India.
          </p>
        </p>
      </div>
      <p className="mt-6 text-md mx-[100px]">
        Trending fields- Commercial Pilot, Private Jet Pilot, Aircraft
        Maintenance Engineer etc.
      </p>
      <div className="flex flex-wrap mt-8 mx-[100px]">
        <div className="items-left w-[400px] h-[200px] mb-[40px] ">
          <img
            src={require("../../assets/careerlibrary/12.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">Pilot</p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px] ml-10">
          <img
            src={require("../../assets/careerlibrary/13.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">
            Aircraft Maintenance Engineer
          </p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[40px] ml-10">
          <img
            src={require("../../assets/careerlibrary/14.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">
            Air Traffic Controller
          </p>
        </div>
        <div className="items-left w-[400px] h-[200px] mb-[80px] mt-8">
          <img
            src={require("../../assets/careerlibrary/15.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px]  text-center">
            Aircraft Maintenance Engineering
          </p>
        </div>
      </div>
    </div>
  );
};

export default Aviation;
