import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import logo from "../../assets/logo/logo.png";
import BurgerAndMenu from "../Menu/BurgerAndMenu";
import "./header.css";

const Header = () => {
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 10) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location.pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <header
      className={`fixed top-0 left-0 w-full z-50 flex lg:h-[4vw] h-[70px] items-center justify-between px-4 py-2 logo-header ${
        isScrolled ? "bg-[#ffffff]" : "bg-transparent"
      } transition-all duration-300 ease-in-out`}
    >
      <NavLink to="/">
        <div className="flex items-center">
          <div className="mr-4">
            <img
              src={logo}
              alt="logo"
              className="max-h-[150px] max-w-[150px] px-4"
            />
          </div>
        </div>
      </NavLink>

      <div className="md:flex hidden">
        <nav className="flex space-x-10 px-10">
          <NavLink
            to="/"
            className={`whitespace-nowrap hover:text-gray-700 font-semibold hover:underline ${
              isScrolled ? "text-[black]" : ""
            }`}
          >
            Home
          </NavLink>
          <NavLink
            to="/students"
            className={`whitespace-nowrap hover:text-gray-700 font-semibold hover:underline ${
              isScrolled ? "text-[black]" : ""
            }`}
          >
            For Students
          </NavLink>

          <NavLink
            to="/institutes"
            className={`whitespace-nowrap hover:text-gray-700 font-semibold hover:underline ${
              isScrolled ? "text-[black]" : ""
            }`}
          >
            For Institutes
          </NavLink>
          <div
            className="relative inline-block text-left"
            onMouseEnter={toggleDropdown}
            onMouseLeave={closeDropdown}
          >
            <div>
              <button
                to="/resources"
                className={`p-2 rounded-xl hover:text-gray-700 font-semibold mt-[-10px] hover:underline ${
                  isScrolled ? "text-[black]" : ""
                }`}
              >
                Resources
              </button>
            </div>
            {isDropdownOpen && (
              <div className=" bg-white  resources  w-full right-0 mx-auto fixed whitespace-nowrap text-center origin-top-right rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className=" flex flex-row ">
                  <div className="flex-1 p-5 text-left ">
                    <h3 className="font-bold text-xl">Career Library</h3>
                    <p className="text-sm mt-4 text-left">
                      Everything you need to know, from colleges to scope, for
                      hundreds of careers.
                    </p>
                    <ul className=" text-left mt-4 text-xl">
                      <a href="/actuarials">
                        <li className="text-sm mt-2">Actuarial Sciences</li>
                      </a>
                      <a href="/animations">
                        <li className="text-sm mt-2">Animation & Graphics</li>
                      </a>
                      <a href="/applieds">
                        <li className="text-sm mt-2">Applied Arts</li>
                      </a>
                      <a href="/architectures">
                        <p className="text-sm mt-2">Architecture</p>
                      </a>
                      <a href="/aviations">
                        <p className="text-sm mt-2">Aviation</p>
                      </a>
                      <a href="/cabins">
                        <p className="text-sm mt-2">Cabin Crew</p>
                      </a>
                      <a href="/civils">
                        <p className="text-sm mt-2">Civil Services</p>
                      </a>
                      <a href="/commerces">
                        <p className="text-sm mt-2">Commerce & Accounts</p>
                      </a>
                      <a href="/applications">
                        <p className="text-sm mt-2">
                          Computer Application & IT
                        </p>
                      </a>
                      <a href="defenses">
                        <p className="text-sm mt-2">Defense</p>
                      </a>
                      <a href="/designs">
                        <p className="text-sm mt-2">Design</p>
                      </a>
                      <a href="/economics">
                        <p className="text-sm mt-2">Economics</p>
                      </a>
                      <a href="/engineerings">
                        <p className="text-sm mt-2">Engineering</p>
                      </a>
                    </ul>
                    <a href="/allcareers">
                      <button className="text-sm mt-2 text-blue-500">
                        View All Careers ›
                      </button>
                    </a>
                  </div>
                  <div className="flex-1 p-5 text-left ml-[-100px]">
                    <h3 className="font-bold">Expert Blogs :</h3>
                    <p className="text-sm mt-4">
                      Expert-written articles on all things career - trends,
                      inspiration, and guidance.
                    </p>
                    <div className="flex flex-row p-2"></div>
                    <div className="flex flex-row p-2"></div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <NavLink
            to="/contacts"
            className={`whitespace-nowrap hover:text-gray-700 font-semibold hover:underline ${
              isScrolled ? "text-[black]" : ""
            }`}
          >
            Contact Us
          </NavLink>
        </nav>
      </div>

      <div className="md:hidden flex ">
        <BurgerAndMenu />
      </div>
    </header>
  );
};

export default Header;
