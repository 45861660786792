import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRupeeSign, faLock } from "@fortawesome/free-solid-svg-icons";

const Planpricing = () => {
  return (
    <div className=" mx-auto">
      <div className="text-3xl font-bold text-black text-center mt-[100px]">
        <h2>Plans & Pricing</h2>
      </div>
      <div className="max-w-[1240px] mx-auto flex gap-8 mt-10 mb-10 px-10">
        <div className="shadow-xl h-[650px] w-[600px] my-4 border border-2px rounded-2xl hover:scale-110 duration-100">
          <p className="text-2xl text-[#4ca6f0] mt-4 text-justify mr-4 ml-4 font-bold">
            EXPLORE
          </p>
          <p className="ml-4 py-2 text-xl">
            Stream Assessment + Counselling <br></br>
            <FontAwesomeIcon icon={faRupeeSign} className="mt-8 mr-4 " />
            9,400
          </p>
          <h3 className="text-xl text-[black] ml-4 mt-10 ">
            Stream Assessment
          </h3>
          <p className="ml-4 text-sm">
            4-dimensional stream assessment with top stream recommendations.
          </p>
          <h3 className="text-xl text-[black] ml-4 mt-8 ">Career Content</h3>
          <p className="ml-4 text-sm">
            Well-researched information on hundreds of career options
          </p>
          <h3 className="text-xl text-[black] ml-4 mt-8 ">
            Career Counselling
          </h3>
          <p className="ml-4 text-sm ">
            Face-to-face counselling sessions and guidance from career experts
            and online support.
          </p>
          <button className="w-[200px] h-[50px] border border-1xl rounded-xl bg-[#4ca6f0] text-white text-1xl mt-8 mx-auto ml-[70px] hover:scale-110 duration-100">
            Buy Now
          </button>
        </div>
        <div className="shadow-xl h-[650px] w-[600px] my-4 border border-2px rounded-2xl  hover:scale-110 duration-100 ">
          <p className="text-2xl text-[#4ca6f0] mt-4 text-justify mr-4 ml-4 font-bold">
            LEARN
          </p>
          <p className="ml-4 py-2 text-xl">
            Stream Assessment<br></br>
            <FontAwesomeIcon icon={faRupeeSign} className="mt-8  px-2" />
            2,400
          </p>
          <h3 className="text-xl text-[black] ml-4 mt-10 ">
            Stream Assessment
          </h3>
          <p className="ml-4 text-sm">
            4-dimensional stream assessment with top stream recommendations.
          </p>
          <h3 className="text-xl text-[black] ml-4 mt-8 ">Career Content</h3>
          <p className="ml-4 text-sm">
            Well-researched information on hundreds of career options
          </p>
          <FontAwesomeIcon icon={faLock} className="text-xl mt-8 ml-4" />
          <h3 className="text-xl text-[black] ml-10 mt-[-30px] ">
            Career Counselling
          </h3>
          <p className="ml-4 text-sm">
            Face-to-face counselling sessions and guidance from career experts
            and online support.
          </p>
          <button className="w-[200px] h-[50px] border border-1xl rounded-xl bg-[#4ca6f0] text-white text-1xl mt-8 mx-auto ml-[70px] hover:scale-110 duration-100">
            Buy Now
          </button>
        </div>
        <div className="shadow-xl h-[650px] w-[600px] my-4 border border-2px rounded-2xl  hover:scale-110 duration-100 ">
          <p className="text-2xl text-[#4ca6f0] mt-4 text-justify mr-4 ml-4 font-bold">
            PREVIEW
          </p>
          <p className="ml-4 py-2 text-xl">
            Orientation Style Assessment <br></br>
            <FontAwesomeIcon icon={faRupeeSign} className="mt-8  px-2" />
            Free
          </p>
          <h3 className="text-xl text-[black] ml-4 mt-10 ">
            Stream Assessment
          </h3>
          <p className="ml-4 text-sm">
            4-dimensional stream assessment with top stream recommendations.
          </p>
          <h3 className="text-xl text-[black] ml-4 mt-8 ">Career Content</h3>
          <p className="ml-4 text-sm">
            Well-researched information on hundreds of career options
          </p>
          <FontAwesomeIcon icon={faLock} className="text-xl mt-8 ml-4" />
          <h3 className="text-xl text-[black] ml-10 mt-[-30px] ">
            Career Counselling
          </h3>
          <p className="ml-4 text-sm">
            Face-to-face counselling sessions and guidance from career experts
            and online support.
          </p>
          <button className="w-[200px] h-[50px] border border-1xl rounded-xl bg-[#4ca6f0] text-white text-1xl mt-8 mx-auto ml-[70px] hover:scale-110 duration-100">
            Take Free Demo
          </button>
        </div>
      </div>
    </div>
  );
}

export default Planpricing


// import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faRupeeSign, faLock } from "@fortawesome/free-solid-svg-icons";

// const Planpricing = () => {
//   return (
//     <div className="flex items-center">
//       <div className=" mx-auto max-w-[1280px]">
//         <div className="lg:text-3xl text-2xl font-bold text-black text-center mt-8 md:mt-16">
//           <h2>Plans & Pricing</h2>
//         </div>
//         <div className="max-w-[1240px] mx-auto flex flex-col lg:flex-row gap-8 mt-10 mb-10 mr-4 ml-4">
//           <div className="shadow-xl md:h-[700px] md:w-[700px] w-full my-4 border border-2px rounded-2xl ">
//             {/* ... (rest of your EXPLORE plan) ... */}
//             <p className="lg:text-3xl text-md text-[#4ca6f0] mt-4 text-justify mr-4 ml-4 font-bold">
//               EXPLORE
//             </p>
//             <p className="ml-4 lg:text-2xl text-md">
//               Stream Assessment + Counselling <br></br>
//               <FontAwesomeIcon icon={faRupeeSign} className="mt-8 mr-4 " />
//               9,400
//             </p>
//             <h3 className="lg:text-2xl text-md text-[black] ml-4 mt-10 ">
//               Stream Assessment
//             </h3>
//             <p className="ml-4">
//               4-dimensional stream assessment with top stream recommendations.
//             </p>
//             <h3 className="lg:text-2xl text-sm text-[black] ml-4 mt-8 ">
//               Career Content
//             </h3>
//             <p className="ml-4">
//               Well-researched information on hundreds of career options
//             </p>
//             <h3 className="lg:text-2xl text-md text-[black] ml-4 mt-8 ">
//               Career Counselling
//             </h3>
//             <p className="ml-4">
//               Face-to-face counselling sessions and guidance from career experts
//               and online support.
//             </p>
//             <button className="lg:w-[250px] lg:h-[50px] w-[150px] h-[30px] border border-1xl rounded-xl bg-[#4ca6f0] text-white text-sm lg:text-2xl mt-8 mx-auto ml-[70px] mb-4">
//               Buy Now
//             </button>
//           </div>
//           <div className="shadow-xl md:h-[700px] md:w-[700px] w-full my-4 border border-2px rounded-2xl ">
//             {/* ... (rest of your LEARN plan) ... */}
//             <p className="lg:text-3xl text-sm text-[#4ca6f0] mt-4 text-justify mr-4 ml-4 font-bold">
//               LEARN
//             </p>
//             <p className="ml-4 lg:text-2xl text-md">
//               Stream Assessment<br></br>
//               <FontAwesomeIcon icon={faRupeeSign} className="mt-[60px] mr-4" />
//               2,400
//             </p>
//             <h3 className="lg:text-2xl text-md text-[black] ml-4 mt-10 ">
//               Stream Assessment
//             </h3>
//             <p className="ml-4">
//               4-dimensional stream assessment with top stream recommendations.
//             </p>
//             <h3 className="lg:text-2xl text-sm text-[black] ml-4 mt-8 ">
//               Career Content
//             </h3>
//             <p className="ml-4">
//               Well-researched information on hundreds of career options
//             </p>
//             <FontAwesomeIcon icon={faLock} className="text-xl mt-8 ml-4" />
//             <h3 className=" lg:text-2xl text-md text-[black] ml-10 mt-[-30px] ">
//               Career Counselling
//             </h3>
//             <p className="ml-4">
//               Face-to-face counselling sessions and guidance from career experts
//               and online support.
//             </p>
//             <button className="lg:w-[250px] lg:h-[50px] w-[150px] h-[30px] border border-1xl rounded-xl bg-[#4ca6f0] text-white lg:text-2xl text-sm mt-8 mx-auto ml-[70px] mb-4">
//               Buy Now
//             </button>
//           </div>
//           <div className="shadow-xl md:h-[700px] md:w-[700px] w-full my-4 border border-2px rounded-2xl ">
//             {/* ... (rest of your PREVIEW plan) ... */}
//             <p className="lg:text-3xl text-md text-[#4ca6f0] mt-4 text-justify mr-4 ml-4 font-bold">
//               PREVIEW
//             </p>
//             <p className="ml-4 lg:text-2xl text-md">
//               Orientation Style Assessment <br></br>
//               <FontAwesomeIcon icon={faRupeeSign} className="mt-[60px]  mr-4" />
//               Free
//             </p>
//             <h3 className="lg:text-2xl text-md text-[black] ml-4 mt-10 ">
//               Stream Assessment
//             </h3>
//             <p className="ml-4">
//               4-dimensional stream assessment with top stream recommendations.
//             </p>
//             <h3 className="lg:text-2xl text-md text-[black] ml-4 mt-8 ">
//               Career Content
//             </h3>
//             <p className="ml-4">
//               Well-researched information on hundreds of career options
//             </p>
//             <FontAwesomeIcon icon={faLock} className="text-xl mt-8 ml-4" />
//             <h3 className="text-md lg:text-2xl text-[black] ml-10 mt-[-30px] ">
//               Career Counselling
//             </h3>
//             <p className="ml-4">
//               Face-to-face counselling sessions and guidance from career experts
//               and online support.
//             </p>
//             <button className="lg:w-[250px] lg:h-[50px] w-[150px] h-[30px] border border-1xl rounded-xl bg-[#4ca6f0] text-white lg:text-2xl text-sm  mt-8 mx-auto ml-[70px] mb-4">
//               Take Free Demo
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Planpricing;
