import React from "react";
import { topCollegesData } from "../../constant/dummyData";
import "./topcolleges.css";

const TopColleges = () => {
  return (
    <div className=" mx-auto max-w-[1280px]">
      <div className="text-center">
        <h1 className="lg:text-3xl text-md font-bold mt-[80px]">
          Our Students Admits
        </h1>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 pt-10">
        {topCollegesData.map((item, index) => (
          <div
            className="college-item bg-white shadow-box3 rounded-[8px] transition-all duration-100 pt-10 pb-[28px] px-6 text-center hover:shadow-box4 border-t-4 border-transparent hover:border-secondary cursor-pointer"
            key={index}
          >
            <div className="w-[170px] h-[170px] rounded-full relative mx-auto mb-8 ">
              <img
                src={item.img}
                alt=""
                className="w-full h-full object-cover rounded-full"
              />
            </div>
            <div className="course-content">
              <h4 className="lg:text-2xl text-1xl mb-1 font-bold">
                {item.name}
              </h4>
              <div>{item.title}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopColleges;
