import React from "react";

const Actuarialscience = () => {
  return (
    <div className="  mt-[100px]">
      <div className="w-full grid-cols-1 flex items-center justify-center">
        <div className="w-full h-[300px] bg-[#64964d]">
          <h2 className="text-white text-3xl  text-center mt-[120px]">
            Career Library
          </h2>
        </div>
      </div>
      <div className="mx-[100px] text-justify">
        <h3 className="text-2xl mt-10 ">Actuarial Sciences</h3>
        <hr ></hr>
        <p className=" mt-6 text-md text-justify">
          Actuaries are among the highest paid professionals today. Actuarial
          Sciences are often called financial astrologers applying statistical
          methods and mathematical skills to determine level of risk in finance,
          insurance and other professions. Endless opportunities, assurance of
          high salary and high job security are some of the reasons why
          actuarial sciences is emerging to be a sought after career option. An
          actuary may specialize in health insurance, life insurance, property
          and casualty insurance or pension and retirement benefits. It is a
          field that requires intense numerical and statistical knowledge.
          <br></br>{" "}
          <p className="mt-4">
            An actuary has a fellowship of bodies like Actuarial Society of
            India (ASI) Mumbai/ Institute of Actuaries London. Many universities
            also offer graduate degrees such as B.Sc. in Insurance, or in
            Actuarial Science and post graduate degrees like M.B.A. in Actuarial
            Science, or Diploma Courses in Actuarial Science.
          </p>
          <br></br>{" "}
          <p className="mt-4">
            Top recruiters in this sector include Insurance Companies, Banking
            Companies, Government Bodies & Consulting Companies.
          </p>
        </p>
      </div>
      <p className=" mt-8 text-md mx-[100px]">
        {" "}
        Trending Field: Health Insurance, Life Insurance
      </p>
      <div className="flex flex-wrap mt-8 mx-[100px]">
        <div className="items-left w-[400px] h-[200px] mb-[80px] ">
          <img
            src={require("../../assets/background/123.png")}
            alt=""
            className="w-full h-full object-cover"
          />
          <p className="text-md font-semibold mt-[20px] mb-[40px] text-center">Actuarial Science</p>
        </div>
      </div>
    </div>
  );
};

export default Actuarialscience;
