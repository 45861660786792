import React from "react";

const Forinstitutes = () => {
  return (
    <div className=" mx-auto max-w-[1280px]">
      <div className="lg:text-3xl text-2xl font-bold text-black text-center mt-10">
        <h2>Our services /Approach to help Institutions</h2>
      </div>
           <div className="max-w-[1240px] mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-6 mt-10 mr-4 ml-4">
        <div className="flex-none shadow-xl h-[200px] w-[300px] mx-auto my-4 border border-2px rounded-2xl hover:scale-110 duration-100 hover:bg-[#f3f0f0] flex flex-col items-center">
          <img
            src={require("../../assets/icon/testing.png")}
            alt=""
            className="w-24 h-30 object-cover rounded-2xl mt-8"
          />
          <p className="text-2xl text-black mt-6 text-center mr-4 ml-4">
            Testing PLatform
          </p>
        </div>
        <div className="flex-none shadow-xl h-[200px] w-[300px] mx-auto my-4 border border-2px rounded-2xl hover:scale-110 duration-100 hover:bg-[#f3f0f0] flex flex-col items-center">
          <img
            src={require("../../assets/icon/session9.png")}
            alt=""
            className="w-24 h-30 object-cover rounded-2xl mt-4"
          />
          <p className="text-2xl text-black mt-6 text-center mr-4 ml-4">
            Sessions
          </p>
        </div>
        <div className="flex-none shadow-xl h-[200px] w-[300px] mx-auto my-4 border border-2px rounded-2xl hover:scale-110 duration-100 hover:bg-[#f3f0f0] flex flex-col items-center">
          <img
            src={require("../../assets/icon/career fair.png")}
            alt=""
            className="w-24 h-30 object-cover rounded-2xl mt-4"
          />
          <p className="text-2xl text-black mt-6 text-center mr-4 ml-4">
            Career Fairs
          </p>
        </div>
        <div className="flex-none shadow-xl h-[200px] w-[300px] mx-auto my-4 border border-2px rounded-2xl hover:scale-110 duration-100 hover:bg-[#f3f0f0] flex flex-col items-center">
          <img
            src={require("../../assets/icon/enagement.png")}
            alt=""
            className="w-24 h-28 object-cover rounded-2xl mt-2"
          />
          <p className="text-2xl text-black mt-6 text-center mr-4 ml-4">
            Engagement Program
          </p>
        </div>
        <div className="flex-none shadow-xl h-[200px] w-[300px] mx-auto my-4 border border-2px rounded-2xl hover:scale-110 duration-100 hover:bg-[#f3f0f0] flex flex-col items-center">
          <img
            src={require("../../assets/icon/scholoships.png")}
            alt=""
            className="w-24 h-28 object-cover rounded-2xl mt-2"
          />
          <p className="text-2xl text-black mt-6 text-center mr-4 ml-4">
            Scholorships
          </p>
        </div>
        <div className="flex-none shadow-xl h-[200px] w-[300px] mx-auto my-4 border border-2px rounded-2xl hover:scale-110 duration-100 hover:bg-[#f3f0f0] flex flex-col items-center">
          <img
            src={require("../../assets/icon/profile.png")}
            alt=""
            className="w-24 h-28 object-cover rounded-2xl mt-2"
          />
          <p className="text-2xl text-black mt-6 text-center mr-4 ml-4">
            Profile Evalution
          </p>
        </div>
      </div>
    </div>
  );
};

export default Forinstitutes;
