import React from "react";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import Cabincrew from "../Components/Cabincrew/Cabincrew";


export const CabinCrew = () => {
  return (
    <div className="overflow-hidden">
      <Header />
      <Cabincrew/>
      <Footer />
    </div>
  );
};
